import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import store from '../store'

import homePage from '@/views/home/index'
import indexPage from '@/views/index/index'
import marketPage from '@/views/market/market'
import tradePage from '@/views/trade/trade'
import financePage from '@/views/finance/finance'
import assestPage from '@/views/assest/assest'
import defiPage from '@/views/defi/defi'

import loginPage from '@/views/login/login'
import registerPage from '@/views/login/register'
import resetPasswordPage from '@/views/login/resetPassword'

import noticePage from '@/views/index/notice'

import rechargePage from '@/views/assest/recharge'
import withdrawPage from '@/views/assest/withdraw'
import transferPage from '@/views/assest/transfer'
import accountPage from '@/views/assest/account'
import assestLogPage from '@/views/assest/assestLog'
import logDetailPage from '@/views/assest/logDetail'

import settingPage from '@/views/setting/setting'
import safeSettingPage from '@/views/setting/safeSetting'
import addressPage from '@/views/setting/address'
import addAddressPage from '@/views/setting/addAddress'
import headerPage from '@/views/setting/header'
import editPasswordPage from '@/views/setting/editPassword'
import editSafePasswordPage from '@/views/setting/editSafePassword'
import giftPage from '@/views/setting/gift'
import activityPage from '@/views/setting/activity'
import sharePage from '@/views/setting/share'
import userAuthPage from '@/views/setting/userAuth'
import commonSettingPage from '@/views/setting/commonSetting'
import langPage from '@/views/setting/lang'
import linePage from '@/views/setting/line'
import helpPage from '@/views/setting/help'
import feedbackPage from '@/views/setting/feedback'
import aboutPage from '@/views/setting/about'
import downloadPage from '@/views/setting/download'
import networkPage from '@/views/setting/network'
import kefuPage from '@/views/setting/kefu'

import financeDetailPage from '@/views/finance/financeDetail'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      index: 3
    },
    component: loginPage
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      index: 4
    },
    component: registerPage
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: {
      index: 4
    },
    component: resetPasswordPage
  },
  {
    path: '/home',
    name: 'home',
    component: homePage,
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          index: 1
        },
        component: indexPage,
      },
      {
        path: '/market',
        name: 'market',
        meta: {
          index: 1
        },
        component: marketPage,
      },
      {
        path: '/trade',
        name: 'trade',
        meta: {
          index: 1
        },
        component: tradePage,
      },
      {
        path: '/finance',
        name: 'finance',
        meta: {
          index: 1
        },
        component: financePage,
      },
      {
        path: '/defi',
        name: 'defi',
        meta: {
          index: 1
        },
        component: defiPage,
      },
      {
        path: '/assest',
        name: 'assest',
        meta: {
          index: 1
        },
        component: assestPage,
      },
      
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      index: 4
    },
    component: noticePage
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      index: 3
    },
    component: rechargePage
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      index: 3
    },
    component: withdrawPage
  },
  {
    path: '/transfer',
    name: 'transfer',
    meta: {
      index: 3
    },
    component: transferPage
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      index: 2
    },
    component:accountPage
  },
  {
    path: '/assestLog',
    name: 'assestLog',
    meta: {
      index: 4
    },
    component: assestLogPage
  },
  {
    path: '/logDetail',
    name: 'logDetail',
    meta: {
      index: 5
    },
    component: logDetailPage
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      index: 2
    },
    component: settingPage
  },
  {
    path: '/safeSetting',
    name: 'safeSetting',
    meta: {
      index: 3
    },
    component: safeSettingPage
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      index: 3
    },
    component: addressPage
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    meta: {
      index: 4
    },
    component: addAddressPage
  },
  {
    path: '/header',
    name: 'header',
    meta: {
      index: 4
    },
    component: headerPage
  },
  {
    path: '/editPassword',
    name: 'editPassword',
    meta: {
      index: 4
    },
    component: editPasswordPage
  },
  {
    path: '/editSafePassword',
    name: 'editSafePassword',
    meta: {
      index: 4
    },
    component: editSafePasswordPage
  },
  {
    path: '/gift',
    name: 'gift',
    meta: {
      index: 3
    },
    component: giftPage
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      index: 3
    },
    component: activityPage
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      index: 3
    },
    component: sharePage
  },
  {
    path: '/userAuth',
    name: 'userAuth',
    meta: {
      index: 3
    },
    component: userAuthPage
  },
  {
    path: '/commonSetting',
    name: 'commonSetting',
    meta: {
      index: 3
    },
    component: commonSettingPage
  },
  {
    path: '/lang',
    name: 'lang',
    meta: {
      index: 4
    },
    component: langPage
  },
  {
    path: '/line',
    name: 'line',
    meta: {
      index: 4
    },
    component: linePage
  },
  {
    path: '/financeDetail',
    name: 'financeDetail',
    meta: {
      index: 2
    },
    component: financeDetailPage
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      index: 5
    },
    component: helpPage
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      index: 4
    },
    component: feedbackPage
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      index: 3
    },
    component: aboutPage
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      index: 4
    },
    component: downloadPage
  },
  {
    path: '/network',
    name: 'network',
    meta: {
      index: 4
    },
    component: networkPage
  },
  {
    path: '/kefu',
    name: 'kefu',
    meta: {
      index: 4
    },
    component: kefuPage
  },
  
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  routes
})

const whiteArr = ['register', 'login', 'lang', 'index', 'market', 'trade', 'setting', 'commonSetting', 'notice', 'help', 'feedback', 'about', 'line', 'network', 'download','kefu']
router.beforeEach((to, from, next) => {
  let token = getToken()
  if (token) {
    next()
  } else {
    if (whiteArr.indexOf(to.name) > -1) {
      next()
    } else {
      next('login')
      // next()
    }
  }
})

router.afterEach(() => {
  window.scrollTo({top: 0})
})

export default router
