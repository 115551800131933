<template>
  <div>
<!--    <div  class=" top-bar-page kefu-page">-->
<!--      <top-bar :title="i18n.t('common.kefu')" class="black" parentId=".kefu-page"  @back="back"></top-bar>-->
<!--    </div>-->
    <!--    <iframe class="iframe-page" :src="kefuUrl" frameborder="0"></iframe>-->
    <div v-if="LiveChat">
      <LiveChatWidget
          :customerName="username"
          :customerEmail="username"
          :group="uid"
          :sessionVariables="session"
          :license="license"
          :visibility="maximized"
          v-on:visibility-changed="handleNewEvent"
          v-on:availability-changed="availabilityChanged"
          v-on:customer-status-changed="customerStatusChanged"
          v-on:form-submitted="formSubmitted"
          v-on:rating-submitted="ratingSubmitted"
          v-on:greeting-hidden="greetingHidden"
          v-on:greeting-displayed="greetingDisplayed"
      />
    </div>
  </div>
</template>
<script>
import "@/utils/sdk.js"
import topBar from '@/components/topBar'
import { customerList } from '@/api/setting'
import {getUser, getUserId,getToken} from "@/utils/auth";
import { LiveChatWidget } from '@livechat/widget-vue/v2'
export default {
  components: {
    topBar,
    LiveChatWidget
  },
  data() {
    return {
      kefuUrl: '',
      maximized: "maximized",
      LiveChat: false,
      session: {},
      username: "",
      token: "",
      uid: "",
      license: ""
    }
  },
  mounted() {
    this.username = getUser()
    this.uid = getUserId()
    this.token = getToken()
    console.log("uid", this.uid)
    this.session = {
      username: getUser(),
      uid: getUserId(),
      token: getToken(),
    }
    this.init()
  },
  methods: {
    // 身份验证
    customIdentityProvider() {
      const baseAPI = window.location.origin
      const userId = getUserId()

      const getNewToken = async () => {
        return this.token
      }

      const getFreshToken = async () => {
        return this.token
      }

      const hasToken = async () => {
        return this.token
      }

      const invalidateToken = async () => {
        const response = await fetch(`${baseAPI}/api/user/invalidate/${userId}`)
        const data = await response.json()
        console.log(data)
      }

      return {
        getNewToken,
        getFreshToken,
        hasToken,
        // invalidate: invalidateToken,
      }
    },
    back() {
      this.$router.go(-1)
      window.$chatwoot.toggleBubbleVisibility('hide');
    },
    formSubmitted(event){
      console.log("formSubmitted", event)
    },
    ratingSubmitted(event){
      console.log("ratingSubmitted", event)
    },
    greetingHidden(event){
      console.log("greetingHidden", event)
    },
    greetingDisplayed(event){
      console.log("greetingDisplayed", event)
    },
    availabilityChanged(event){
      console.log("availabilityChanged", event)
    },
    visibilityChanged(event){
      console.log("visibilityChanged", event)
    },
    customerStatusChanged(event){
      console.log("customerStatusChanged", event)
      if (event.status === "browsing") {
        this.maximized = "hidden"
      }
    },
    handleNewEvent(event) {
      console.log('LiveChatWidget.onNewEvent', event)
      if(event.visibility === "hidden") {
        this.$router.go(-1)
      }
    },
    meiqia(){
      let that = this
      _MEIQIA('withoutBtn');
      _MEIQIA('showPanel');
      // 传递顾客信息
      _MEIQIA('metadata', {
        ID: getUserId(), // 美洽默认字段
        name: getUser(), // 美洽默认字段
        username: getUser(), // 美洽默认字段
      });
      _MEIQIA('getPanelVisibility', function (visibility){
        console.log("customerList getPanelVisibility")
        if (visibility === 'visible') {
          // _MEIQIA('hidePanel');
        }
      });
    },
    init(){
        //客服
        customerList().then(res => {
          let that = this
            for(var i in res.data.Items) {
                let data = res.data.Items[i]
                if(data.Type === 'meiqia') {
                  this.meiqia()
                }
                if (data.Type === 'livechatinc') {
                  this.LiveChat = true
                  this.license = data.Link
                }
            }
            // console.log(this.kefuUrl)
            // this.kefuUrl = res.data.Items.filter(item => item.type === '"meiqia"').Link
        })
    },
  }
}
</script>