<template>
  <div class="login-page">
    <div class="login-top flex-center-between">
      <div class="back">
        <img :src="backUrl" alt="" @click="back">
      </div>
      <div class="text" @click="toLogin">{{i18n.t('register.login')}}</div>
    </div>

    <div class="title">{{i18n.t('register.title')}}</div>

    <div class="tabs">
      <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{i18n.t('register.tab')}}</div>
      <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{i18n.t('register.tab2')}}</div>
    </div>


    <div class="login-form">
      <div class="form-item  flex-center" v-show="tabIndex == 1 && step == 1" >
        <div class="icon">
          <img :src="emailUrl" alt="">
        </div>
        <div class="input"> 
          <input type="text" v-model="form.username"   :placeholder="i18n.t('register.labelPlaceholder1')">
        </div>
      </div>

      <div class="form-item  flex-center" v-if="tabIndex == 2 && step == 1">
        <div class="pre flex-center" @click="changePre">
          <img :src="phoneUrl" alt="">
          {{phonePre}}
        </div>
        <div class="flex-1">
          <div class="input clear"> 
            <input type="tel" v-model="form.phone" :placeholder="i18n.t('register.labelPlaceholder8')">
          </div>
        </div>
      </div>

      <div class="form-label" v-show="step == 1">
        {{i18n.t('register.label')}}
      </div>

      <div class="form-item clear flex-center" v-show="step == 1" >
        <div class="icon">
          <img :src="userUrl" alt="">
        </div>
        <div class="input"> 
          <input type="tel"  v-model="form.inviteCode"  :placeholder="i18n.t('register.labelPlaceholder5')">
        </div>
      </div>

    

      <div class="form-item flex-center" v-show="step == 2" >
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input"> 
          <input :type="showPassword ? 'text' : 'password'" @input="changePassword" v-model="form.password" :placeholder="i18n.t('register.labelPlaceholder3')">
        </div>
        <div class="adot" @click="changeShowPassword">
          <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

      <div class="password-auth" v-show="showPasswordAuth">
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(0) > -1 ? checkUrl2 : checkUrl" alt="">
          <span>{{i18n.t('register.role1')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(1) > -1 ? checkUrl2 : checkUrl" alt="">
          <span>{{i18n.t('register.role2')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(2) > -1 ? checkUrl2 : checkUrl" alt="">
          <span>{{i18n.t('register.role3')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(3) > -1 ? checkUrl2 : checkUrl" alt="">
          <span>{{i18n.t('register.role4')}}</span>
        </div>
      </div>

      <div class="form-item flex-center" v-show="step == 2" >
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input">
          <input type="tel" pattern="[0-9]*" style="-webkit-text-security:disc" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="form.safePassword" :placeholder="i18n.t('register.labelPlaceholder4')"/>

<!--          <input :type="showSafePassword ? 'tel' : 'password'"  v-model="form.safePassword" :placeholder="i18n.t('register.labelPlaceholder4')">-->
        </div>
        <div class="adot"  @click="changeShowSafePassword">
          <img :src="showSafePassword ? eyeUrl2 : eyeUrl" alt="">
        </div>
      </div>

      <!-- <div class="form-select flex-center-start" v-show="step == 2" @click="changeShowInvite">
        <span>邀请码（可选）</span>
        <img :src="downUrl" alt="" :class="showInvite ? '' : 'hide'">
      </div> -->

      
    </div>

    <div class="role flex-center-start" v-show="step == 2" @click="changeRole">
      <img :src="checkRole ? checkedUrl : checkedUrl2" alt="">
      <span>{{i18n.t('register.role5')}}</span>
      <span class="high" @click.stop="showRole(0)">{{i18n.t('register.role6')}}</span>
      <span>{{i18n.t('register.role7')}}</span>
      <span class="high" @click.stop="showRole(1)">{{i18n.t('register.role8')}}</span>
    </div>

    <Button class="login-btn" @click="submit">{{i18n.t(`register.btn${step}`)}}</Button>

		<Popup v-model:show="showCodePopup"  lock-scroll class="rate-popup">
			<div class="rate-modal register-auth">
				<div class="title flex-center-between clear">
					<div>{{i18n.t('register.auth1')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="desc">
          {{i18n.t('register.auth2')}} {{form.username}}
        </div>

        <div class="code-box" >
          <div class="input flex-center">
            <input ref="codeInput" type="tel" v-model="form.code" @input="checkCodeSize"  />
          </div>
          <div class="number-box flex-center-between">
            <div class="box flex-center" :class="getCodeActive(i)" v-for="i in 6" :key="i" @click="inputCode">{{getCodeStr(i)}}</div>
          </div>
        </div>

        <div class="send-info">
          <span v-if="!isDisabled" @click="resend">{{i18n.t('register.btn1')}}</span>
          <span v-if="isDisabled">{{timeCount}}</span>
        </div>

        <div class="auth-btn">
          <Button :disabled="form.code.length < 6" @click="toSet">{{i18n.t('register.btn3')}}</Button>
        </div>
			</div>
		</Popup>

    <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-start">
          <img :src="backUrl" alt="" @click="cancel">
          <span>{{i18n.t('register.labelPlaceholder6')}}</span>
        </div>
        <div class="search-list flex-center-between">
          <div class="flex-center">
            <img :src="searchUrl" alt="">
            <input type="text" v-model="keyword" :placeholder="i18n.t('register.labelPlaceholder7')" @input="searchCountry" @change="searchCountry">
          </div>
          <div @click="cancel">{{i18n.t('register.cancel')}}</div>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="list flex-center-between" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div>
              {{child.key}}
            </div>
          </div>
        </div>
			</div>
    </Popup>
    <role-page :roleData="roleData" v-show="showRoleModal" @cancel="cancel"></role-page>
  </div>
</template>
<script>
  import { Button, Popup } from 'vant'
  import rolePage from './role'
  import { register, sendCode, checkEmailCode, sendSms, checkPhoneCode, userArticle } from '@/api/login'
  import { setToken, setUser, setUserId } from '@/utils/auth'
  export default {
    components: {
      Button,
      Popup,
      rolePage
    },
    data() {
      return {
        step: 1,
        form: {
          username: '',
          phone: '',
          password: '',
          safePassword: '',
          code: '',
          inviteCode: ''
        },
        showCodePopup: false,
        backUrl: require('../../assets/imgs/icon-back.png'),
        closeUrl: require('../../assets/imgs/icon-close.png'),
        emailUrl: require('../../assets/imgs/icon-email2.png'),
        passwordUrl: require('../../assets/imgs/icon-password.png'),
        eyeUrl: require('../../assets/imgs/icon-eye.png'),
        eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
        userUrl: require('../../assets/imgs/icon-users.png'),
        downUrl: require('../../assets/imgs/icon-arrow-down3.png'),
        checkedUrl: require('../../assets/imgs/icon-checked.png'),
        checkedUrl2: require('../../assets/imgs/icon-uncheck.png'),
        checkUrl: require('../../assets/imgs/icon-check.png'),
        checkUrl2: require('../../assets/imgs/icon-check2.png'),
        phoneUrl: require('../../assets/imgs/icon-phone2.png'),
        searchUrl: require('../../assets/imgs/icon-search.png'),
        showPassword: false,
        showSafePassword: false,
        activeIndex: 1,
        isDisabled: false,
        sendTime: '',
        checkRole: false,
        showInvite: true,
        showPasswordAuth: false,
        passwordAuthList: [],
        phonePre: '+1',
        tabIndex: 1,
        showPopup: false,
        showRoleModal: false,
        countryList: [],
        keyword: '',
        serviceData: {},
        privacyData: {},
        roleData: {}
      }
    },
    computed: {
      timeCount() {
        return this.sendTime + 's'
      },
      countryListBak() {
        return this.$store.state.countryList
      }
    },
    mounted() {
      let code = this.$route.query.code
      if (code) {
        this.form.inviteCode = code
      }
      this.countryList = [].concat(this.countryListBak)
      this.initArticle()
    },
    methods: {
      initArticle() {
        let form = new FormData()
        let form2 = new FormData()
        form.append('Type', 0)
        form2.append('Type', 1)
        //服务条款
        userArticle(form).then(res => {
          let data = res.data.Items
          if (data && data.length > 0) {
            this.serviceData = data[0]
          }
        })
        //隐私策略
        userArticle(form2).then(res => {
          let data = res.data.Items
          if (data && data.length > 0) {
            this.privacyData = data[0]
          }
        })
      },
      changeTab(index) {
        if (this.step == 1) {
          this.tabIndex = index
        } 
      },
      back() {
        this.$router.go(-1)
      },
      toLogin() {
        this.$router.push({
          name: 'login'
        })
      },
      toSet() {
        if (!this.form.code || (this.form.code + '').length != 6) {
          this.$store.state.warningMsg = this.i18n.t('register.labelPlaceholder2')
          return
        }
        let form = new FormData()
        form.append('Code', this.form.code)
        form.append('Type', 1)
        if (this.tabIndex == 1) {
          form.append('Email', this.form.username)
          checkEmailCode(form).then(res => {
            if (res.code == 0) {
              this.step = 2
              this.showCodePopup = false
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        } else {
          form.append('Phone', this.phonePre + this.form.phone)
          checkPhoneCode(form).then(res => {
            if (res.code == 0) {
              this.step = 2
              this.showCodePopup = false
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        }
      },
      submit() {
        if (this.step == 1) {
          if (this.tabIndex == 1) {
            this.sendEmail()
          } else {
            this.sendPhoneSms()
          }
        } else {
          if (!this.form.password) {
           this.$store.state.warningMsg = this.i18n.t('register.labelPlaceholder3')
            return
          }
          if (!this.checkRole) {
            this.$store.state.warningMsg = this.i18n.t('register.labelPlaceholder9')
            return
          }

          let form = new FormData()
          if (this.tabIndex == 1) {
            form.append('Email', this.form.username)
          } else {
            form.append('Phone', this.phonePre + this.form.phone)
            form.append('CountryCode', this.phonePre)
          }
          form.append('Password', this.form.password)
          form.append('SafePassword', this.form.safePassword)
          form.append('InviteCode', this.form.inviteCode)
          form.append('Code', this.form.code)
          register(form).then(res => {
            if (res.code == 0) {
              this.$store.state.successMsg = this.i18n.t('register.success')
              this.$router.push({
                name: 'login'
              })
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        }
      },
      sendPhoneSms(){
        if (!this.form.phone) {
          this.$store.state.warningMsg = this.i18n.t('register.labelPlaceholder8')
          return
        }
        // if (!this.form.inviteCode) {
        //   this.$store.state.warningMsg = this.i18n.t('register.labelPlaceholder5')
        //   return
        // }
        let form = new FormData()
        form.append('Phone', this.phonePre + this.form.phone)
        form.append('Invite', this.form.inviteCode)
        form.append('Type', 1)
        sendSms(form).then(res => {
          if (res.code == 0) {
            this.showCodePopup = true
            this.countDown(60)
            this.$nextTick(() => {
              this.inputCode()
            }, 10)
          } else {
            this.$store.state.errorMsg = res.msg
          }
        })
      },
      sendEmail(){
        if (!this.form.username) {
          this.$store.state.warningMsg = this.i18n.t('register.labelPlaceholder1')
          return
        }
        // if (!this.form.inviteCode) {
        //   this.$store.state.warningMsg = this.i18n.t('register.labelPlaceholder5')
        //   return
        // }
        let form = new FormData()
        form.append('Email', this.form.username)
        form.append('Invite', this.form.inviteCode)
        form.append('Type', 1)
        sendCode(form).then(res => {
          if (res.code == 0) {
            this.showCodePopup = true
            this.countDown(60)
            this.$nextTick(() => {
              this.inputCode()
            }, 10)
          } else {
            this.$store.state.errorMsg = res.msg
          }
        })
      },
      countDown(time) {
        if (time > 0) {
          this.isDisabled = true
          time -= 1
          this.sendTime = time
          setTimeout(() => {
            this.countDown(time)
          }, 1000)
        } else {
          this.sendTime = ''
          this.isDisabled = false
        }
      },
      cancel() {
        this.showCodePopup = false
        this.showPopup = false
        this.showRoleModal = false
      },
      checkCodeSize() {
        let code = this.form.code + ''
        if (code.length > 6) {
          code = code.substring(0, 6)
          this.form.code = parseInt(code)
        }
      },
      getCodeActive(i) {
        let code = this.form.code + ''
        if (!code) {
          return i == this.activeIndex ? 'active' : ''
        }
        return i == code.length + 1 ? 'active' : ''
      },
      inputCode() {
        this.activeIndex = 1
        this.$refs.codeInput.focus()
      },
      getCodeStr(i) {
        let code = this.form.code + ''
        if (!code) {
          return ''
        }
        let str = code.substring(i - 1, i)
        return str
      },
      resend() {
        if (this.isDisabled) {
          return
        } else {
          if(this.tabIndex === 1){
            this.sendEmail()
          }else{
            this.sendPhoneSms()
          }
        }
      },
      changeRole() {
        this.checkRole = !this.checkRole
      },
      changeShowPassword() {
        this.showPassword = !this.showPassword
      },
      changeShowSafePassword() {
        this.showSafePassword = !this.showSafePassword
      },
      changeShowInvite() {
        this.showInvite = !this.showInvite
      },
      changePassword() {
        if (this.step == 1) {
          return
        }
        this.showPasswordAuth = true
        let password = this.form.password
        let result = []
        if (password.length >= 8 && password.length <= 30) {
          result.push(0)
        }
        if (/[a-z]/g.test(password)) {
          result.push(1)
        }
        if (/[A-Z]/g.test(password)) {
          result.push(2)
        }
        if (/[0-9]/g.test(password)) {
          result.push(3)
        }

        this.passwordAuthList = result
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      searchCountry() {
        if (this.keyword) {
          this.countryList = this.countryListBak.map(v => {
            let children = v.children.filter(val => {
              return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
            if (children.length > 0) {
              return {
                children: children
              }
            } else {
              return {
                children: []
              }
            }
          })
        } else {
          this.countryList = [].concat(this.countryListBak)
        }
      },
      showRole(type) {
        if (type == 0) {
          this.roleData = this.serviceData
          this.roleData.Title = this.i18n.t('register.role6')
        } else {
          this.roleData = this.privacyData
          this.roleData.Title = this.i18n.t('register.role8')
        }
        this.showRoleModal = true
      }
    },
  }
</script>