<template>
  <div class="home-container page-top">
    <div class="modal">
      <div class="flex-center-between top fixed">
        <img :src="avatar ? headerObj[avatar] : userUrl" alt="" @click="toPath('setting')">
        <img :src="emailUrl" alt="" class="small" @click="toPath('notice')">
      </div>

      <div class="banner">
        <Swipe
          :autoplay="3000"
          :show-indicators="false"
        >
          <SwipeItem  v-for="(item, i) in bannerList" :key="i" >
            <div class="flex-center-between banner-inner" :class="`type${item.Position}`">
              <img :src="`${item.Img}`" alt="">
              <div class="content">
                <div class="line line1" >{{item.Content}}</div>
                <div class="line line2" >{{item.Content1}}</div>
              </div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>

      <div class="modal-title">{{i18n.t('index.title1')}}</div>

      <div class="recommend-list flex">
        <div class="recommend" v-for="(item, i) in recommendList" :key="i">
          <div class="flex">
            <div class="name">
              <span class="white">{{item.Name}}</span>/USDT
            </div>
            <div class="percent" :class="item.percent >= 0 ? 'green' : 'red'">
              {{item.percent >= 0 ? '+' : ''}}{{item.percent}}
              <i class="iconfont " :class="item.percent >= 0 ? 'icon-arrowTop' : 'icon-arrowDown'"></i>
            </div>
          </div>
          <div class="count" :class="item.percent >= 0 ? 'green' : 'red'">{{item.count}}</div>
        </div>
      </div>

      <div class="recharge-btn flex-center" @click="toRecharge">
        {{i18n.t('index.label1')}}
      </div>

      <div class="share-kefu flex-center-between">
        <div class="share flex-center-between" @click="toQucik">
          <div class="flex-1 w-1">
            <div class="title" :class="i18n.t('index.label2').length > 15 ? 'small' : ''">{{i18n.t('index.label7')}} </div>
            <div class="desc" v-show="false" :class="i18n.t('index.label3').length > 8 ? 'small' : ''">
              <span>{{i18n.t('index.label2')}}</span>
              <span class="yellow">{{i18n.t('index.label3')}}</span>
            </div>
          </div>
          <div class="icon">
            <img :src="shareUrl" alt="">
          </div>
        </div>
        <div class="share flex-center-between">
          <div class="" @click="toKefu">
            <div class="title" :class="i18n.t('index.label4').length > 15 ? 'small' : ''">{{i18n.t('index.label4')}}</div>
            <div class="desc" :class="i18n.t('index.label5').length > 15 ? 'small' : ''">
              <span>{{i18n.t('index.label5')}}</span>
            </div>
          </div>
          <div class="icon">
            <img :src="kefuUrl" alt="">
          </div>
        </div>
      </div>

    </div>

    <div class="modal clear">
      <div class="modal-title top">{{i18n.t('index.title2')}}</div>

      <div class="rankList">

        <bit-page :dataList="rankList" @detail="toDetail"></bit-page>
      </div>

        <div class="more flex-center" @click="toPath('market')">
          <span>{{i18n.t('index.label6')}}</span>
          <img :src="moreUrl" alt="">
        </div>
      </div>


      <!--充值类型-->
      <Popup v-model:show="showRecharge" position="bottom" lock-scroll class="rate-popup">
        <div class="rate-modal">
          <div class="title flex-center-between">
            <div>{{i18n.t('recharge.label5')}}</div>
            <img :src="closeUrl" alt="" @click="cancel">
          </div>
          <div class="recharge-list ">
            <div class="list flex-center" v-for="(item, i) in rechargeList" :key="i" @click="changeRecharge(item, i)">
              <div class="icon">
                <img :src="item.icon" alt="">
              </div>
              <div class="name flex-1">
                <span>{{item.name}}</span>
                <span class="desc">{{item.desc}}</span>
              </div>
              <div class="check">
                <img :src="rechargeIndex == i ? radioUrl2 : radioUrl" alt="">
              </div>
            </div>
            <div class="btn">
              <Button :disabled="rechargeIndex === -1" @click="toRechargePage">{{i18n.t('recharge.btn4')}}</Button>
            </div>
          </div>

        </div>
      </Popup>
      <!--初始页-->
      <div class="welcome-page flex-column-center" v-show="showWelcome">
        <img :src="logoUrl" alt="">
        <div class="label">Bitdogs</div>
        <div>DIGITAL  ASSET  EXCHANGE</div>
        <div class="space">数字资产交易所</div>
      </div>

      <!--客服-->
      <Popup v-model:show="showKefu" position="bottom" lock-scroll class="rate-popup">
        <div class="rate-modal bottom">
          <div class="title flex-center-between">
            <div>{{i18n.t('setting.label12')}}</div>
            <img :src="closeUrl" alt="" @click="cancel">
          </div>
          <div class="recharge-list kefu">
            <div class="list flex-center" v-for="(item, i) in kefuList" :key="i" @click="chooseKefu(item, i)">
              <div class="icon">
                <img :src="`${item.Avatar}`" alt="">
              </div>
              <div class="name flex-1">
                <div>{{item.Name}}</div>
                <div class="status">{{i18n.t('setting.label13')}}</div>
              </div>
              <div class="check">
                <img :src="rightUrl" alt="">
              </div>
            </div>
          </div>

        </div>
      </Popup>
<!--    快捷购买 -->
    <Popup v-model:show="showQuick" position="bottom" lock-scroll class="rate-popup">
      <div class="rate-modal bottom">
        <div class="title flex-center-between">
          <div>{{i18n.t('index.label7')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
        </div>
        <div class="recharge-list kefu">
          <div class="list flex-center" v-for="(item, i) in QuickPurchaseList" :key="i" @click="chooseQucik(item, i)">
            <div class="icon">
              <img :src="`${item.Avatar}`" alt="">
            </div>
            <div class="name flex-1">
              <div>{{item.name}}</div>
<!--              <div class="status">{{i18n.t('setting.label13')}}</div>-->
            </div>
            <div class="check">
              <img :src="rightUrl" alt="">
            </div>
          </div>
        </div>

      </div>
    </Popup>
    </div>
</template>

<script>
import { getCoinList, getBannerList, quoteList } from '@/api/index'
import { userInfo } from '@/api/user'
import { Button, Swipe, SwipeItem, Popup } from 'vant'
import moment from 'moment'
import bitPage from '@/components/bit'
import { initWebSocket } from '@/utils/common'
import { setUser, setUserAvatar, setUserId, getUserAvatar, getToken } from '@/utils/auth'
import { customerList } from '@/api/setting'
import "@/utils/sdk.js"
export default {
  name: 'about',
  components: {
    Button,
    Swipe,
    SwipeItem,
    Popup,
    bitPage
  },
  data() {
    return {
      webSocket: '',
      avatar: '',
      userUrl: require('../../assets/imgs/icon-user.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      emailUrl: require('../../assets/imgs/icon-email.png'),
      shareUrl: require('../../assets/imgs/icon-share.png'),
      kefuUrl: require('../../assets/imgs/icon-kefu.png'),
      usdtUrl: require('../../assets/imgs/icon-usdt.png'),
      moreUrl: require('../../assets/imgs/icon-arrow-right.png'),
      radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
      logoUrl: require('../../assets/imgs/logo1.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      bannerList: [],
      showRecharge: false,
      isInitQuote: false,
      showQuick: false,
      rechargeIndex: -1,
      rechargeName: '',
      recommendList: [
        {
          Name: 'BTC',
          percent: 0,
          count: 0
        },
        {
          Name: 'ETH',
          percent: 0,
          count: 0
        },
        {
          Name: 'ADA',
          percent: 0,
          count: 0
        },
      ],
      QuickPurchaseList:[
        {
          // Avatar: require('../../assets/imgs/taansak.png'),
          name: 'Mercuryo',
          desc: 'Tether USDT',
          link: "https://exchange.mercuryo.io/",
          key: 'Mercuryo'
        },
        {
          Avatar: require('../../assets/imgs/wire-transfer.png'),
          name: 'Wire Transfer',
          desc: 'Tether USDT',
          // link: "https://ramp.network/buy",
          key: 'Transfer'
        },
        {
          Avatar: require('../../assets/imgs/network.svg'),
          name: 'NetWork',
          desc: 'Tether USDT',
          link: "https://ramp.network/buy",
          key: 'NetWork'
        },

        {
          Avatar: require('../../assets/imgs/monpay.png'),
          name: 'MoonPay',
          desc: 'Tether USDT',
          link: "https://buy.moonpay.com/",
          key: 'MoonPay'
        },
      ],
      rechargeList: [
        {
					icon: require('../../assets/imgs/bit1.png'),
					name: 'TRC20',
					desc: 'Tether USDT',
					key: 'USDT-TRC20'
				},
				{
					icon: require('../../assets/imgs/bit1.png'),
					name: 'ERC20',
					desc: 'Tether USDT',
					key: 'USDT-ERC20'
				},
				{
					icon: require('../../assets/imgs/bit2.png'),
					name: 'ETH',
					desc: 'Tether USDT',
					key: 'Ethereum'
				},
				{
					icon: require('../../assets/imgs/bit3.png'),
					name: 'BTC',
					key: 'Bitcoin'
				},
        // {
				// 	icon: require('../../assets/imgs/bit4.png'),
				// 	name: 'Bank',
				// 	key: 'bank'
				// },
        {
          icon: require('../../assets/imgs/bit5.png'),
          name: 'USDC-TRC20',
          key: 'USDC-TRC20'
        },
        {
          icon: require('../../assets/imgs/bit5.png'),
          name: 'USDC-ERC20',
          key: 'USDC-ERC20'
        },
      ],
      rankListBak: [],
      rankList: [  ],
      bitListBak: [],
      quoteListBak: [],
      headerObj:{
        'header-1':  require('../../assets/imgs/header/header-1.png'),
        'header-2':  require('../../assets/imgs/header/header-2.png'),
        'header-3':  require('../../assets/imgs/header/header-3.png'),
        'header-4':  require('../../assets/imgs/header/header-4.png'),
        'header-5':  require('../../assets/imgs/header/header-5.png'),
        'header-6':  require('../../assets/imgs/header/header-6.png'),
        'header-7':  require('../../assets/imgs/header/header-7.png'),
        'header-8':  require('../../assets/imgs/header/header-8.png'),
        'header-9':  require('../../assets/imgs/header/header-9.png'),
        'header-10':  require('../../assets/imgs/header/header-10.png'),
        'header-11':  require('../../assets/imgs/header/header-11.png'),
        'header-12':  require('../../assets/imgs/header/header-12.png'),
        'header-13':  require('../../assets/imgs/header/header-13.png'),
        'header-14':  require('../../assets/imgs/header/header-14.png'),
      },
      showKefu: false,
      kefuList: []
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    isSend() {
      return this.$store.state.isSend
    },
    bitList() {
      return this.$store.state.bitList
    },
    showWelcome() {
      return this.$store.state.showWelcome
    },
  },
  mounted() {
    this.init()
    this.initSocket()

    //15秒后数据加载失败也显示页面
    setTimeout(() => {
      this.$store.state.showWelcome = false
    }, 15000)
  },
  beforeDestroy() {
    this.$store.state.isSend = false
    if (this.webSocket && this.webSocket.close) {
      this.$store.state.isClose = true
      this.webSocket.close()
    }
  },
  methods: {
    getUserAvatar,
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    initQuote() {
      //最新行情
      quoteList().then(res => {
        this.quoteListBak = res.data
        this.$store.state.showWelcome = false
        let data = res.data
        data.forEach(v => {
          let index = this.bitListBak.findIndex(val => {
            return v.Currency.ID == val.ID
          })
          if (index > -1) {
            this.bitListBak[index].count = v.Close
          }
        })
      })
    },
    init() {
      //币种列表
      if (this.bitList.length == 0) {
        getCoinList().then(res => {
          this.bitListBak = res.data.Items.map(v => {
            v.percent = 0
            return v
          })
          this.rankListBak = res.data.Items.map(v => {
            v.percent = 0
            return v
          })
          this.rankList = this.rankListBak.slice(0, 8)
          // this.recommendList = this.rankListBak.slice(0, 3)
          this.$store.state.bitList = [].concat(this.bitListBak)
          this.initQuote()
        })
      } else {
        this.rankListBak = [].concat(this.bitList).sort((a, b) => {
          return Math.abs(b.percent) - Math.abs(a.percent)
        })
        this.rankList = this.rankListBak.slice(0, 8)
        this.initQuote()
        // this.recommendList = this.rankListBak.slice(0, 3)
      }
      //轮播图
      getBannerList().then(res => {
        this.bannerList = res.data.Items
      })

      //客服
      customerList().then(res => {
        this.kefuList = res.data.Items
      })

      //用户信息
      if (getToken()) {
        userInfo().then(res => {
          let data = res.data
          setUser(data.Email)
          setUserId(data.ID)
          setUserAvatar(data.Avatar)
          this.avatar = `header-${data.Avatar}`
          this.$store.state.shareCode = data.InviteCode
        })
      } else {
        this.avatar = ''
      }
    },
    async initSocket(){ //初始化weosocket
      let client = await initWebSocket(-1, this.websocketonmessage, this.initSocket)
      this.webSocket = client
    },
    websocketonmessage(e){ //数据接收
      let data = e.data
      let redata = {}
      if (data.indexOf('Welcome') > -1) {
				console.log(1111, '订阅')
				this.webSocket.send(JSON.stringify({
          action: 'subscribe',
          topic: 'all',
          message: ''
        }))
			}
      try {
        redata = JSON.parse(e.data)
        this.setListData(redata)
      }
      catch(e) {
      }
    },
    setListData(data) {
      let index = this.rankListBak.findIndex(v => {
        return v.Name + '-USDT' == data.tags.InstId
      })
      let recommendIndex = this.recommendList.findIndex(v => {
        return v.Name + '-USDT' == data.tags.InstId
      })
      let bitIndex = this.bitListBak.findIndex(v => {
        return v.Name + '-USDT' == data.tags.InstId
      })
      let valObj = data.fields
      let Channel = data.tags.Channel
      let percent = parseFloat(((valObj.Close - valObj.Yesterday) / valObj.Yesterday * 100).toFixed(2))
      if (index > -1) {
        this.rankListBak[index] = {
          ...this.rankListBak[index],
          ...valObj,
          percent,
          Channel,
          count: valObj.Close,
        }
        this.bitListBak[bitIndex] = {
          ...this.bitListBak[bitIndex],
          ...valObj,
          percent,
          Channel,
          count: valObj.Close,
        }
        this.$store.state.bitList = [].concat(this.bitListBak)
      }

      if (recommendIndex > -1) {
        this.recommendList[recommendIndex] = {
          ...this.recommendList[recommendIndex],
          ...valObj,
          percent,
          count: valObj.Close,
        }
      }

      this.rankListBak.sort((a, b) => {
        return Math.abs(b.percent) - Math.abs(a.percent)
      })
      this.rankList = this.rankListBak.slice(0, 8)
      // this.recommendList = this.rankListBak.slice(0, 3)
    },
    toDetail(data) {
      this.$router.push({
        name: 'trade',
        query: {
          name: data.Name,
          id: data.ID
        }
      })
    },
    toRecharge() {
      this.showRecharge = true
    },
    changeRecharge(data, i) {
      this.rechargeIndex = i
      this.rechargeName = data.name
    },
    toRechargePage() {
      if (this.rechargeName == 'Bank') {
        this.$router.push({
          name: 'kefu'
        })
      } else {
        this.$router.push({
          path: '/recharge?type=' + this.rechargeName
        })
      }
    },
    cancel() {
      this.showRecharge = false
      this.showKefu = false
    },
    toKefu() {
      this.showKefu = true
    },
    toQucik() {
      this.showQuick = true
    },
    chooseKefu(data) {
      this.showKefu = false
      this.to(data.Type, data.Link)
    },
    chooseQucik(data) {
      this.showQuick = false
      if(data.key == "Transfer") {
        if(this.kefuList.length > 0 ) {
          this.to(this.kefuList[0].Type,this.kefuList[0].link)
        }
        return
      }
      this.toKefuPath(data.link)
    },
    to(Type, Link) {
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else if (Type == 'meiqia') {
        this.$router.push({
          name: 'kefu'
        })
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
          case "line":
            Link = "https://line.me/R/"+ Link
            break;
          case "livechatinc":
            this.$router.push({
              name: 'kefu'
            })
            return
            break;
        }
        this.toKefuPath(Link)
      }
    },
    toKefuPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
  },
}
</script>
<style lang="less">

</style>
