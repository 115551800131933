<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
    <div class="safe-left" id="safeLeft"></div>
    <div class="safe-right" id="safeRight"></div>
    <Popup v-model:show="showMsg" @close="cancel" lock-scroll class="message-popup">
      <div class="message-modal flex-column-center">
        <img :src="successUrl" alt="" v-if="iconType == 'success'">
        <img :src="errorUrl" alt="" v-if="iconType == 'error'">
        <img :src="warningUrl" alt="" v-if="iconType == 'warning'">
        <div class="msg">{{message}}</div>
        <Button @click="cancel" v-if="iconType == 'warning' || iconType == 'error'">{{i18n.t('common.ensure')}}</Button>
      </div>
    </Popup>
    <LiveChatWidget v-if="LiveChat"
                    :license="license"
                    visibility="hidden"
    />
  </div>
</template>
<script>
import { Popup, Button} from 'vant'
import { configList, LoginAddress } from '@/api/user'
import loadJs from "@/assets/loadjs"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getDefaultLanguage,getLocalName } from '@/utils/common'
import {customerList} from "@/api/setting";
import {ethers} from "ethers";
import {getToken, getUser, getUserId, setToken, setUser, setUserId} from "@/utils/auth";
import {LiveChatWidget} from "@livechat/widget-vue/v2";
export default {
  components: {
    LiveChatWidget,
    Popup,
    Button
  },
  data() {
    return {
      successUrl: require('./assets/imgs/icon-success.png'),
      errorUrl: require('./assets/imgs/icon-error.png'),
      warningUrl: require('./assets/imgs/icon-warning2.png'),
      transitionName: '',
      hideKefu: false,
      message: '',
      showMsg: false,
      LiveChat: false,
      iconType: '',
      isRunMeiqiaScript: false,
      addr: '',
      license: '',
      balance: 0,
      from: '', // 发送方地址
      recipient: '', // 接收方地址
      amount: '', // 转账金额
      signature: ''
    };
  },
  computed: {
    successMsg() {
      return this.$store.state.successMsg
    },
    errorMsg() {
      return this.$store.state.errorMsg
    },
    warningMsg() {
      return this.$store.state.warningMsg
    },
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right'
      } else if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'
      }
      let name = this.$route.name
      if (name == 'download') {
        this.hideKefu = true
      }
    },
    successMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('success')
        } 
      }
    },
    warningMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('warning')
        } 
      }
    },
    errorMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('error')
        } 
      }
    },
  },

  mounted() {
    if(window.ethereum !== null) {
      this.ethConnect()
    }
    // if(window.tronWeb !== null) {
    //   this.tronConnect()
    // }
    this.init()
    let locale = localStorage.getItem('locale')
    this.runMeiqiaScript()
    // console.log("getDefaultLanguage")
    // console.log(locale)
    const defaultLanguage = getDefaultLanguage()
    this.i18n.locale = locale ? locale : defaultLanguage
    if (localStorage.getItem('locale') == null) {
        localStorage.setItem('locale', this.i18n.locale)
    }
    // console.log(localStorage.getItem('localeName'))
    const name = getLocalName(this.i18n.locale)
    // console.log(name)
    localStorage.setItem('localeName', name)

    //左右滑
    this.resetMover()

    //计算字体缩放比例
    let screenWidth =  document.getElementById('app').offsetWidth
    if (screenWidth > 540) {
      screenWidth = 540
    }
    this.$store.state.screenScale = screenWidth / 375

    let leftDom = document.getElementById('safeLeft')
    let rightDom = document.getElementById('safeRight')
    if (leftDom) {
      leftDom.addEventListener('touchstart', (e) => {
        e.preventDefault()
      })
      leftDom.addEventListener('touchmove', (e) => {
        e.preventDefault()
      })
    }
    if (rightDom) {
      rightDom.addEventListener('touchstart', (e) => {
        e.preventDefault()
      })
      rightDom.addEventListener('touchmove', (e) => {
        e.preventDefault()
      })
    }
  },
  methods: {
    async tronConnect() {
      const res = await tronLink.request({method: 'tron_requestAccounts'})
      console.log("tronConnect")
      if (res.code === 200) {
        this.addr = window.tronWeb.defaultAddress.base58;
        console.log("this.addr");
        console.log(this.addr);
        console.log(window.tronWeb.defaultAddress);

        // Get the message to be signed (You may customize this message)
        const message = this.i18n.t('index.login');
        const hex = Buffer.from(message, 'utf8').toString('hex');
        // const hex = '0x01EF'
        // Sign the message using TronLink

        // Sign the transaction
        const signedTransaction = await tronWeb.trx.sign(hex);

        // Extract the signature from the signed transaction
        // const signature = signedTransaction.signature;
        console.log("signature");
        console.log(signedTransaction);
        // Send the signature and address to the server for verification
        LoginAddress({
          "Signature": signedTransaction,
          "Address": this.addr,
          "Hex": window.tronWeb.defaultAddress.hex,
          "Message": hex,
          "Type":"tron"
        }).then(res => {
          console.log(res);
          // Handle the response from the server as per your requirements
        }).catch(err => {
          console.error("Error sending signature to the server:", err);
          // Handle error if any
        });
      }
    },
    //登录钱包签名
     async ethConnect () {
      if (window.ethereum !== null) {
        if(getToken()){
          return
        }
        //连接浏览器默认的web3钱包
        const provider = new ethers.BrowserProvider(window.ethereum);
        //签名
        const signer = await provider.getSigner();
        //获取签名的地址
        this.addr = await signer.getAddress();
        //获取余额
        this.balance = ethers.formatEther(await provider.getBalance(this.addr));
        const message = this.i18n.t('index.login');
        this.signature = await signer.signMessage(message);
        LoginAddress({
          "Signature": this.signature,
          "Address": this.addr,
          "Message": message,
          "Type":"eth"
        }).then(res => {
          console.log(res)
          setToken(res.data.token)
          setUser(this.addr)
          setUserId(res.data.id)
        })
      };
    },
    runMeiqiaScript() {
      // if(this.isRunMeiqiaScript) {
      //   return
      // }
      // this.isRunMeiqiaScript = true
      //客服
      customerList().then(res => {
        let that = this
        // console.log(res.data.Items)
        // this.kefuList = res.data.Items
        for(var i in res.data.Items) {
          let data = res.data.Items[i]
          if(data.Type === 'meiqia') {
            const script = document.createElement('script');
            script.async = true;
            script.charset = 'UTF-8';
            script.src = 'https://static.meiqia.com/dist/meiqia.js';
            document.head.appendChild(script);
            window._MEIQIA = window._MEIQIA || function() {
              (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
            };
            _MEIQIA = window._MEIQIA
            // console.log(data)
            this.kefuUrl = data.Link + '&language=en&metadata={"ID":"' +window.localStorage.WebUserId+ '","email":"'+  window.localStorage.WebUserName+'"}'
            // this.runMeiqiaScript()
            const match = this.kefuUrl.match(/eid=([^&]+)/);
            const eidValue = match ? match[1] : null;
            _MEIQIA('entId', eidValue);
            _MEIQIA('withoutBtn');
            // window._MEIQIA('showPanel');
            // 获取聊天窗口可见性
            _MEIQIA('getPanelVisibility', function (visibility){
              console.log("getPanelVisibility")
              // if (visibility === 'visible') {
              //   _MEIQIA('hidePanel');
              // }
            });
            // 初始化成功时的回调
            _MEIQIA('allSet', function (){
              // 你可以根据自己的需求编写相应的代码
              console.log('美洽网站插件初始化完毕。');
            });
            //点击关闭按钮后的回调
            _MEIQIA('beforeCloseWindow', function() {
              // 返回
              that.$router.push({
                name: 'index'
              })
            });
          }
          if (data.Type === 'livechatinc') {
            this.LiveChat = true
            this.license = data.Link
          }

        }

      })
    },
    init() {
      configList().then(res => {
        let data = res

        this.$store.state.promoteUrl = data.promote.Value

        // 语言
        this.$store.state.language = data.language.Value
        this.$store.state.timeZone = data.time_zone.Value
        // 客服
        // this.$store.state.kefuUrl = data.kefu.Value

        let appName = data.app_name.Value
        this.$store.state.appName = appName
        document.title = appName
      })

      // //用户信息
      // userInfo().then(res => {
      //   let data = res.data
      //   setUser(data.Email)
      //   setUserId(data.ID)
      //   setUserAvatar(data.Avatar)

      //   this.$store.state.shareCode = data.InviteCode
      // })
    },
    showMsgModal(type) {
      this.iconType = type
      this.message = type == 'success' ? this.successMsg : (type == 'error' ? this.errorMsg : this.warningMsg)
      this.showMsg = true
      if (type == 'success') {
        setTimeout(() => {
          this.cancel()
        }, 2000)
      }
    },
    cancel() {
      this.showMsg = false
      this.$store.state.successMsg = ''
      this.$store.state.errorMsg = ''
      this.$store.state.warningMsg = ''
    },
    resetMover() {
      let startX = 0
      let startY = 0
      window.document.addEventListener('touchstart', (e) => {
        startX = e.targetTouches[0].pageX
        startY = e.targetTouches[0].pageY
      })
      window.document.addEventListener('touchmove', (e) => {
        let moveX = e.targetTouches[0].pageX
        let moveY = e.targetTouches[0].pageY
        if (Math.abs(moveX - startX) > Math.abs(moveY - startY)) {//左右滑
          e.preventDefault()
        }
      }, { passive: false })
      //
    }
  }
}
</script>

<style>
/* 设置顶部状态栏的背景颜色 */
/* 在 iOS 14 及以上版本中无效 */
/* 在 iOS 13 及以下版本中生效 */
:root {
  --apple-status-bar-color: #000000;
}

/* 设置顶部状态栏的前景颜色 */
/* 在 iOS 14 及以上版本中生效 */
/* 在 iOS 13 及以下版本中无效 */
@media (prefers-color-scheme: dark) {
  /* 暗色模式 */
  /* 在暗色模式下的前景色 */
  :root {
    --apple-status-bar-style: #ffffff
  }
}
@media (prefers-color-scheme: light) {
  /* 亮色模式 */
  /* 在亮色模式下的前景色 */
  :root {
    --apple-status-bar-style: #000000;
  }
}
</style>
<style lang="less">
/* 页面切换动画效果 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  // backface-visibility: hidden;
  // perspective: 1000px;
}
.slide-right-enter,
.slide-right-enter-active {
  animation: bounce-left-in 200ms;
}
.slide-right-leave-active {
  animation: bounce-left-out 200ms;
}
.slide-left-enter,
.slide-left-enter-active {
  animation: bounce-right-in 200ms;
}
.slide-left-leave-active {
  animation: bounce-right-out 200ms;
}

@keyframes bounce-right-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes bounce-left-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
</style>