<template>
  <div class="trade-page page-top big">
    <div class="top-tab flex-center-start">
			<div class="tab" v-for="(item, i) in tabList" :key="i" :class="tabIndex == i ? 'active' : ''" @click="changeTab(i)">{{item}}</div>
    </div>

		<div class="trade-main">
			<div class="trade-info">
				<div class="flex-center-between bit-info">
					<div class="flex-center">
						<img :src="bitUrl" alt="">
						<span class="name" @click="changeBit">{{bitInfo.Name}}/USDT</span>
						<span class="percent" :class="bitInfo.percent > 0 ? 'green' : 'red'">{{bitInfo.percent > 0 ? '+' : ''}}{{bitInfo.percent}}%</span>
					</div>
					<div class="more">
						<img :src="chartUrl" class="trade-more" alt="" v-if="tabIndex == 1">
						<img :src="moreUrl" class="trade-more" alt="" @click="showMore">
					</div>
				</div>

				<div class="flex-start-between mt-8" v-if="tabIndex != 2">
					<!--左侧-->
					<div class="trade-left">
						<div class="action-btn flex" v-if="tabIndex == 0">
							<div class="btn flex-center" :class="tradeType == 'buy' ? 'green' : ''" @click="changeType('buy')">{{i18n.t('trade.buy1')}}</div>
							<div class="btn flex-center"  :class="tradeType == 'sell' ? 'red' : ''" @click="changeType('sell')">{{i18n.t('trade.sell1')}}</div>
						</div>

						<div class="flex" v-if="tabIndex == 1">
							<div class="flex-center scale-btn" @click="changeMultSetting">
								<span>{{multForm.fullType == 'full' ? i18n.t('trade.label1') : i18n.t('trade.label2')}} {{multForm.multiple}}X</span>
								<img :src="downUrl3" alt="">
							</div>
						</div>

						<div class="flex-center-between user-amount">
							<div class="label">{{i18n.t('trade.label3')}}</div>
							<div class="flex-center amount">
								<span v-if="tabIndex == 0">{{tradeType == 'buy' ? parseFloat(userMoney).toFixed(4) : userBitMoney}} {{tradeType == 'buy' ? 'USDT' : bitInfo.Name}}</span>
								<span v-if="tabIndex == 1">{{parseFloat(userContractMoney).toFixed(4)}} USDT</span>
								<img :src="addUrl" alt="" @click="toPath('recharge')">
							</div>
						</div>

						<div class="trade-type flex-center-between" @click="showPicker">
							<div>{{actionTypeName}}</div>
							<img :src="downUrl" alt="">
						</div>
						<div class="trade-money flex-center-between" v-if="actionType == 1">
							<div>
								<input type="number" inputmode="decimal" @input="checkMoney(true)" @change="changeMoney" v-model="tradeMoney" :placeholder="i18n.t('trade.label4')">
							</div>
							<div class="flex-center icon">
								<img :src="minusUrl" alt="" @click="minusMoney">
								<span class="space"></span>
								<img :src="addUrl2" alt="" @click="addMoney">
							</div>
						</div>

						<div class="trade-money clear-bg flex-center-between " v-if="actionType == 3 && tabIndex == 0">
							<div class="left width-full flex-center-between">
								<input type="number" inputmode="decimal" v-model="form.stopPrice" :placeholder="i18n.t('trade.label5')">
								<div>USDT</div>
							</div>
							<!-- <div class="right flex-center trigger-dom" @click="showPicker2">{{form.stopName}}</div> -->
						</div>
						<div class="trade-money clear-bg flex-center-between " v-if="actionType == 3 || actionType == 4">
							<div class="left flex-center-start">
								<input type="number" inputmode="decimal" v-model="tradeMoney" @change="changeMoney" @input="changeMoney(true)" :placeholder="i18n.t('trade.label4')" >
								<!-- <input type="number" inputmode="decimal" v-model="bitInfo.count" @change="changeMoney" @input="changeMoney" :placeholder="i18n.t('trade.label6')" v-if="form.limitType == 2" readonly> -->
							</div>
							<div class="right flex-center limit-dom" @click="showPicker3" >{{form.limitTypeName}}</div>
						</div>

						<div class="price" v-if="(actionType == 1 || actionType == 3) && tabIndex == 0">
							≈ {{(form.limitType == 1 ? tradeMoney : bitInfo.count) || 0}} USDT
						</div>

						<div class="trade-money flex-center-between" :class="actionType != 4 && tabIndex == 0 ? 'clear' : ''" v-if="actionType == 1 || actionType == 3 || actionType == 4">
							<div>
								<input type="number" inputmode="decimal" pattern="[0-9]*" v-model="form.number" @change="changeCount" @input="changeCount" :placeholder="i18n.t('trade.label7')">
							</div>
							<div>{{bitInfo.Name}}</div>
						</div>

						<div class="trade-money flex-center-between" v-if="actionType == 2">
							<div>
								<input v-show="tradeType == 'buy' || tabIndex == 1" type="number" inputmode="decimal" v-model="form.totalMoney" :placeholder="i18n.t('trade.label8')" @change="changeTotal" @input="changeTotal">
								<input v-show="tradeType == 'sell' && tabIndex == 0" type="number" inputmode="decimal" v-model="form.number" :placeholder="i18n.t('trade.label7')" @change="changeCount" @input="changeCount">
							</div>
							<div>{{tradeType == 'buy' || tabIndex == 1 ? 'USDT' : bitInfo.Name}}</div>
						</div>

						<div class="slide-box ">
							<div class="slide-inner flex-center" >
								<Slider v-model="tradeMoneyPercent" step="5" bar-height="4px" active-color="none" inactive-color="none" @change="changeSlide" @drag-start="dragStart" @drag-end="dragEnd">
									 <template #button>
										<div class="custom-button"></div>
									</template>
								</Slider>
								<div class="slide-bg">
									<div class="slide-value flex-center" v-show="showPercent" :style="`left: ${tradeMoneyPercent}%;`">{{tradeMoneyPercent}}%</div>
									<div class="active-line" :style="`width: ${tradeMoneyPercent}%;`"></div>
									<div class="point" v-for="i in 6" :key="i" :class="[(i - 1) * 20 <= tradeMoneyPercent ? 'active' : '', `point${i}`]"></div>
								</div>
							</div>
						</div>

						<div class="statistics" v-if="actionType == 3 && tabIndex == 1">
							<div class="flex-center-between line">
								<div class="label">{{i18n.t('trade.label9')}}</div>
								<div class="flex-cetner">
									<span class="green">{{winForm.winMoney}}</span>
									<span>USDT</span>
								</div>
							</div>
							<div class="flex-center-between line">
								<div class="label">{{i18n.t('trade.label10')}}</div>
								<div class="flex-cetner">
									<span class="red">{{winForm.loseMoney}}</span>
									<span>USDT</span>
								</div>
							</div>
						</div>

						
						<div class="add-btn flex-center" v-if="actionType == 3 && tabIndex == 1" @click="changeWinSetting">
							<img :src="addUrl3" alt="">
							<span>{{i18n.t('trade.label11')}}</span>
						</div>

						<div class="trade-money flex-center-between clear" v-if="actionType == 1 || actionType == 3">
							<div>
								<input type="number" inputmode="decimal" :placeholder="i18n.t('trade.label12')" @change="changeTotal" @input="changeTotal" v-model="form.totalMoney">
							</div>
							<div>USDT</div>
						</div>

						<Button v-if="tabIndex == 0 && isLogin" class="bottom-action-btn" @click="submit" :class="[tradeType == 'buy' ? 'green' : 'red', `type${actionType}`]">{{tradeType == 'buy' ? i18n.t('trade.buy1') : i18n.t('trade.sell1')}} {{bitInfo.Name}}</Button>
						<Button v-if="tabIndex == 0 && !isLogin" class="bottom-action-btn" @click="toPath('login')" :class="[tradeType == 'buy' ? 'green' : 'red', `type${actionType}`]">{{i18n.t('login.title')}}</Button>
						<div class="flex-center-between" v-if="tabIndex == 1 && isLogin">
							<Button class="bottom-action-btn green" @click="submitContract('long')">{{i18n.t('trade.buy2')}}</Button>
							<Button class="bottom-action-btn red" @click="submitContract('short')">{{i18n.t('trade.sell2')}}</Button>
						</div>
						<div class="flex-center-between" v-if="tabIndex == 1 && !isLogin">
							<Button class="bottom-action-btn green" @click="toPath('login')">{{i18n.t('login.title')}}</Button>
						</div>
						<!-- <div class="other-setting" v-if="tabIndex == 1">
							<div class="flex-center-between">
								<div>
									<Checkbox v-model="form.setting1"  shape="square" checked-color="#F7A600">被动订单</Checkbox>
								</div>
								<div class="flex-center">
									<span>GTC</span>
									<img :src="downUrl3" alt="">
								</div>
							</div>
							<div class="mt-5">
								<Checkbox v-model="form.setting2"  shape="square" checked-color="#F7A600">只减仓</Checkbox>
							</div>
						</div> -->
					</div>
					<!--右侧-->
					<div class="trade-right flex-column-start">
						<div class="trade-time" v-if="tabIndex == 1">
							<div class="label">{{i18n.t('trade.label13')}} / {{i18n.t('trade.label14')}}</div>
							<div class="time">0.0100% / 02：02：58</div>
						</div>

						<div class="th-line flex-center-between">
							<div class="th">{{i18n.t('trade.label15')}}</div>
							<div class="th">{{i18n.t('trade.label16')}}</div>
						</div>
						<div class="th-line flex-center-between mb-6">
							<div class="th">(USDT)</div>
							<div class="th">({{bitInfo.Name}})</div>
						</div>

						<div class="flex-center-between list" v-for="(item, i) in upDealList" :key="i" @click="checkPrice(item)">
							<div class="red">{{item.value ? resetValue(item.value) : '--'}}</div>
							<div>{{item.dealCount || '--'}}</div>
							<div class="bg red" :style="getBgWidth(item.count, i, 1)"></div>
						</div>

						<div class="value flex-column-start-center" :class="`type${actionType}`">
							<div class="count" :class="bitInfo.percent > 0 ? 'green' : 'red'">{{bitInfo.count}}</div>
							<div class="small">≈{{bitInfo.count}} USDT</div>
						</div>

						<div class="flex-center-between list" v-for="(item, index) in downDealList" :key="'down' + index" @click="checkPrice(item)">
							<div class="green">{{item.value ? resetValue(item.value) : '--'}}</div>
							<div>{{item.dealCount || '--'}}</div>
							<div class="bg green" :style="getBgWidth(item.count, index, 2)"></div>
						</div>

						<div class="deep-line flex-center-between">
							<div class="picker flex-center-between" @click="showDeepPicker">
								<div>{{deepName}}</div>
								<img :src="downUrl" alt="">
							</div>
							<img :src="deepType == 1 ? defaultUrl : (deepType == 2 ? buyUrl : saleUrl)" alt="" @click="showTypePicker">
						</div>

					</div>
				</div>

				<!--走势图-->
				<div class="chart-box" v-show="tabIndex == 2">
					<div class="price">
						<span class="big">{{bitInfo.count}}</span>
						<span> ≈ {{bitInfo.count}} USDT</span>
					</div>

					<chart-page :id="bitInfo.ID" :showChart="tabIndex == 2"></chart-page>

					<div class="flex-center-end user-info">
						<div class="label">{{i18n.t('trade.label17')}}</div>
						<div class="money">{{tradeType == 'buy' ? parseFloat(userOptionMoney).toFixed(4) : parseFloat(userBitOptionMoney).toFixed(4)}} USDT</div>
						<img :src="transferUrl2" alt="">
					</div>

					<div class="flex-center-between btn-line">
						<Button class="green" @click="showUpDownModal(1)">{{i18n.t('trade.buy3')}}</Button>
						<Button class="red" @click="showUpDownModal(2)">{{i18n.t('trade.sell3')}}</Button>
					</div>
				</div>

			</div>
			<!--订单-->
			<div class="order-info">
				<div class="flex-center-between title">
					<div class="tab-list flex">
						<div class="tab" :class="orderTabIndex == 1 ? 'active' : ''" @click="changeOrderTab(1)">{{i18n.t('trade.label18')}}</div>
						<div class="tab" v-show="tabIndex == 0" :class="orderTabIndex == 2 ? 'active' : ''" @click="changeOrderTab(2)" v-if="tabIndex != 2">{{i18n.t('common.tab5')}}</div>
					</div>
					<div class="flex-center all" @click="showOrder">
						<img :src="orderUrl" alt="">
						<span>{{i18n.t('trade.label19')}}</span>
					</div>
				</div>

				<div class="show-all flex-center-start">
					<Checkbox v-model="showAll"  shape="square" checked-color="#F7A600">{{i18n.t('trade.label20')}}</Checkbox>
				</div>

				<div class="order-list">
					<div class="trade-list" v-if="tabIndex == 0 && orderTabIndex == 1">
						<div class="list" v-for="(item, i) in orderList" :key="i">
							<div class="flex-center-between">
								<div>
									<div class="list-title flex-center-start">
										<span>{{item.Currency.Name}}/USDT</span>
										<span class="tab" :class="item.Trade == 'buy' ? 'green' : 'red'">{{item.Trust == 1 ? i18n.t('trade.label21') : i18n.t('trade.label22')}}-{{item.Trade == 'buy' ? i18n.t('trade.buy1') : i18n.t('trade.sell1')}}</span>
									</div>
									<div class="status" :class="item.Status == 2 ? 'red' : 'green'">{{item.Status == 0 ? i18n.t('trade.status1') : (item.Status == 1 ?  i18n.t('trade.status2') :  i18n.t('trade.status3'))}}</div>
								</div>
								<div class="cancel-btn" v-if="item.Status == 0">
									<Button @click="cancelOrder(item)">{{ i18n.t('trade.btn1')}}</Button>
								</div>
							</div>

							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th1')}}</div>
								<div>{{parseFloat(item.TrustClose).toFixed(4)}} USDT</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th2')}}</div>
								<div>{{parseFloat(item.Close).toFixed(4)}} USDT</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th3')}}</div>
								<div>{{parseFloat(item.CloseCount).toFixed(4)}} USDT</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th4')}}</div>
								<div>{{parseFloat(item.TrustNum).toFixed(4)}} </div>
							</div>
							<div class="list-line flex-center-between">
								<div  class="label">{{i18n.t('trade.th5')}}</div>
								<div>{{parseFloat(item.CloseNum).toFixed(4)}} </div>
							</div>
							<div class="list-line flex-center-between">
								<div  class="label">{{i18n.t('trade.th6')}}</div>
								<div>{{item.CreatedAt}} </div>
							</div>
						</div>
					</div>
					<!--现货资产-->
					<div class="bit-list rankList clear" v-if="tabIndex == 0 && orderTabIndex == 2">
						<div class="list clear rank flex-center-between" v-for="(item, i) in walletBitList" :key="i">
							<div class="icon">
								<img :src="`/upload/${item.Currency.Logo}`" alt="">
							</div>
							<div class="flex-1">
								<div class="flex-center-between name">
									<div>
										{{item.Currency.Name}}
									</div>
									<div >{{parseFloat(item.SpotUse).toFixed(4)}}</div>
								</div>
								<div class="flex-center-between desc">
									<div>{{item.Currency.Remark}}</div>
								
									<div  class="percent" >
										{{resetSpotMoney(item.SpotUse, item.Currency.ID)}} USDT
									</div>
									
								</div>
							</div>
						</div>
					</div>
					<!--合约-->
					<div class="trade-list" v-if="tabIndex == 1">
						<div class="list" v-for="(item, i) in contractOrderList" :key="i">
							<div class="flex-center-between">
								<div class="list-title flex-start">
									<div class="flex-column-start"> 
										<span>{{item.Currency.Name}}/USDT </span>
										<span class="gray">{{item.Full == 'full' ? i18n.t('trade.label1') : i18n.t('trade.label2')}} {{item.Multiple}}X</span>
									</div>
									<div class="tab" :class="[item.Direction == 'long' ? 'green' : 'red', i18n.t('trade.label21').length > 12 ? 'small' : '']">{{item.Trust == 1 ? i18n.t('trade.label21') : i18n.t('trade.label22')}}-{{item.Direction == 'long' ? i18n.t('trade.buy2') : i18n.t('trade.sell2')}}</div>
								
								</div>
								<div class="status clear" :class="item.Status == 2 ? 'red' : 'green'">{{item.Status == 0 ? i18n.t('trade.status1') : (item.Status == 1 ? i18n.t('trade.status4') : i18n.t('trade.status5'))}}</div>
							</div>

							<div class="flex-center list-horizontal-line mt-12">
								<div class="flex-2 label">{{i18n.t('trade.th11')}}</div>
								<div class="flex-3 label">{{i18n.t('trade.th10')}}</div>
								<div class="flex-2 label">{{i18n.t('trade.th9')}}</div>
								<div class="flex-2 label  text-right">{{i18n.t('trade.th12')}}</div>
							</div>

							<div class="flex-center list-horizontal-line">
								<div class="flex-2">{{parseFloat(item.OpenNum).toFixed(4)}}</div>
								<div class="flex-3">{{parseFloat(item.OpenClose).toFixed(4)}}</div>
								<div class="flex-2">{{parseFloat(item.TrustClose).toFixed(4)}}</div>
								<div class="flex-2 text-right">{{parseFloat(item.Close).toFixed(4)}}</div>
							</div>

							<div class="flex-center-between mt-12 btn-line">
								<div class="list-btn " @click="setWinOrLose(item)">
									<span class="green">{{parseFloat(item.TakeProfit) || '--'}}</span>
									<span> / </span>
									<span class="red"> {{parseFloat(item.StopLoss) || ' --'}}</span>
								</div>
								<div class="list-btn" @click="toClearOrder(item)">{{i18n.t('trade.status5')}}</div>
							</div>

							<!-- <div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th7')}}</div>
								<div>{{parseFloat(item.Margin).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th8')}}</div>
								<div>{{parseFloat(item.Actual).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th9')}}</div>
								<div>{{parseFloat(item.TrustClose).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th10')}}</div>
								<div>{{parseFloat(item.OpenClose).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th11')}}</div>
								<div>{{parseFloat(item.OpenNum).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th12')}}</div>
								<div>{{parseFloat(item.Close).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th13')}}</div>
								<div>{{parseFloat(item.CloseNum).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th14')}}</div>
								<div v-if="item.Status == 2">{{item.CloseTime}}</div>
								<div v-else>-</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th15')}}</div>
								<div>{{parseFloat(item.Commission).toFixed(4)}}</div>
							</div> -->
						</div>
					</div>

					<div class="trade-list" v-if="tabIndex == 2">
						<div class="list" v-for="(item, i) in optionOrderArr" :key="i">
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th16')}}</div>
								<div>{{item.Currency.Name}}/USDT</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th17')}}</div>
								<div class="label" :class="item.Status == 2 ? 'red' : 'green'">{{item.Status == 0 ? i18n.t('trade.status6') : (item.Status == 1 ? i18n.t('trade.status7') : i18n.t('trade.status8'))}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th18')}}</div>
								<div class="label">{{item.CreatedAt}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th19')}}</div>
								<div class="label">{{parseFloat(item.MarkPrice).toFixed(4)}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th20')}}</div>
								<div class="label">{{item.SettlementTime}}</div>
							</div>
							<div class="list-line flex-center-between" v-if="item.Status == 1">
								<div class="label">{{i18n.t('trade.th21')}}</div>
								<div class="label">{{item.SettlementPrice}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th22')}}</div>
								<div class="label" :class="item.Direction == 'rose' ? 'green' : 'red'">{{item.Direction == 'rose' ? i18n.t('trade.buy4') : i18n.t('trade.sell4')}}</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th23')}}</div>
								<div class="label">{{item.Settlement}}S</div>
							</div>
							<div class="list-line flex-center-between">
								<div class="label">{{i18n.t('trade.th24')}}</div>
								<div class="label">{{parseFloat(item.Amount).toFixed(4)}} USDT</div>
							</div>
							<div class="list-line flex-center-between" v-if="item.Status == 1">
								<div class="label">{{i18n.t('trade.th25')}}</div>
								<div class="label" :class="item.Profit > 0 ? 'green' : 'red'">{{parseFloat(item.Profit).toFixed(4)}}</div>
							</div>
							<!-- <div class="list-line flex-center-between">
								<div class="label">当前价格</div>
								<div class="label">{{}}</div>
							</div> -->
						</div>
					</div>

					<div class="empty flex-column-center" v-if="(orderList.length == 0 && tabIndex == 0) || (contractOrderList.length == 0 && tabIndex == 1) || (optionOrderArr.length == 0 && tabIndex == 2)">
						<img :src="emptyUrl" alt="">
						<span class="desc">{{i18n.t('trade.empty')}}</span>
						<!-- <div class="msg">抱歉，您当前账户没有资产。</div>
						<div class="flex-center ">
							<div class="flex-column-center link" @click="toPath('recharge')">
								<img :src="rechargeUrl" alt="">
								<span>充值</span>
							</div>
							<div class="flex-column-center link" @click="toPath('transfer')">
								<img :src="transferUrl" alt="">
								<span>划转</span>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<!--市价单、限价单选择-->
		<div class="shade-box" v-show="showPickerModal" @click="cancel">
			<div class="trade-picker-list " :style="`top: ${pickerTop}px;`" >
				<div class="list" v-for="(item, i) in actionTypeList" :key="i" :class="item.value == actionType ? 'active' : ''" @click.stop="changeActionType(item)">
					{{item.text}}
				</div>
			</div>
		</div>
		<!--止盈止损选择-->
		<div class="shade-box" v-show="showPickerModal2" @click="cancel">
			<div class="trade-picker-list small" :style="`top: ${pickerTop2}px;`" >
				<div class="list" v-for="(item, i) in triggerTypeList" :key="i" :class="item.value == form.triggerType ? 'active' : ''" @click.stop="changeTriggerType(item)">
					{{item.text}}
				</div>
			</div>
		</div>
		<!--市价、限价选择-->
		<div class="shade-box" v-show="showPickerModal3" @click="cancel">
			<div class="trade-picker-list small" :style="`top: ${pickerTop3}px;`" >
				<div class="list" v-for="(item, i) in limitTypeList" :key="i" :class="item.value == form.limitType ? 'active' : ''" @click.stop="changeLimitType(item)">
					{{item.text}}
				</div>
			</div>
		</div>

		<!--更多-->
		<div class="shade-box" v-show="showMoreModal" @click="cancel">
			<div class="more-list" :style="`top: ${moreTop}px;`" >
				<div class="list flex-center-start" @click.stop="toInfo('trade')">
					<img :src="lineUrl" alt="">
					<span>{{i18n.t('trade.desc1')}}</span>
				</div>
				<div class="list flex-center-start"  @click.stop="toInfo('rate')">
					<img :src="helpUrl" alt="">
					<span>{{i18n.t('trade.desc2')}}</span>
				</div>
			</div>
		</div>

		<!--委托深度、方式-->
		<Popup v-model:show="showPopup" position="bottom" class="rate-popup">
			<div class="deep-list" v-if="pickerType == 'deep'">
				<div class="list">{{i18n.t('trade.desc3')}}</div>
				<div class="list" v-for="(item, i) in deepList" :key="i" :class="item == deepName ? 'active' : ''" @click="changeDeep(item)">{{item}}</div>
				<div class="list" @click="cancel">{{i18n.t('trade.btn1')}}</div>
			</div>
			<div class="deep-list type" v-if="pickerType == 'type'">
				<div class="list">{{i18n.t('trade.desc4')}}</div>
				<div class="list flex-center-start" v-for="(item, i) in typeList" :key="i" :class="item.type == deepType ? 'active' : ''" @click="changeDeepType(item)">
					<img :src="item.type == 1 ? defaultUrl : (item.type == 2 ? buyUrl : saleUrl)" alt="">
					<span>{{item.title}}</span>
				</div>
				<div class="list" @click="cancel">{{i18n.t('trade.btn1')}}</div>
			</div>
		</Popup>

		<!--费率-->
		<Popup v-model:show="showRate" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('trade.desc5')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>

				<div class="info-line">{{i18n.t('trade.desc6')}}</div>

				<div class="info-line top">{{i18n.t('trade.desc7')}}</div>
				<div class="info-line">{{i18n.t('trade.desc8')}}</div>
				<div class="info-line">{{i18n.t('trade.desc9')}}</div>

				<div class="info-line top">{{i18n.t('trade.desc10')}}</div>
				<div class="info-line top">{{i18n.t('trade.desc11')}}</div>
				<div class="info-line top">{{i18n.t('trade.desc12')}}</div>
				<div class="info-line">{{i18n.t('trade.desc13')}}</div>
				<div class="info-line">{{i18n.t('trade.desc14')}}</div>
				<div class="info-line">{{i18n.t('trade.desc15')}}</div>
				<div class="info-line">{{i18n.t('trade.desc16')}}</div>
				<div class="info-line">{{i18n.t('trade.desc17')}}</div>
				<div class="info-line">{{i18n.t('trade.desc18')}}</div>

				<div class="info-line top">{{i18n.t('trade.desc19')}}</div>
				<div class="info-line">{{i18n.t('trade.desc20')}}</div>
				<div class="info-line">{{i18n.t('trade.desc21')}}</div>
				<div class="info-line">{{i18n.t('trade.desc22')}}</div>
			</div>
		</Popup>

		<!--选择币种-->
		<Popup v-model:show="showBit" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('trade.desc23')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="rankList clear">
					<bit-page :dataList="bitList" @detail="chooseBit" :subscribe="true"></bit-page>
				</div>
			</div>
		</Popup>

		<!--全仓倍数设置弹框-->
		<Popup v-model:show="showMultModal" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('trade.desc24')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="win-setting">
					<div class="action-btn flex">
						<div class="btn flex-center" :class="multForm.fullType == 'full' ? 'green' : ''" @click="changeFullType('full')">{{i18n.t('trade.label1')}}</div>
						<div class="btn flex-center"  :class="multForm.fullType == 'warehousing' ? 'red' : ''" @click="changeFullType('warehousing')">{{i18n.t('trade.label2')}}</div>
					</div>
					<div class="flex-center-between setting-title">
						<div>{{i18n.t('trade.label23')}}</div>
					</div>
					<div class="flex percent-line">
						<div class="percent flex-center" v-for="(item, i) in multList" :key="i" :class="multForm.multiple == item ? 'active' : ''" @click="changeMultiple(item)">{{item}}X</div>
					</div>
					<div class="bottom-btn">
						<Button @click="ensureMult">{{i18n.t('trade.btn2')}}</Button>
					</div>
				</div>
			</div>
		</Popup>

		<!--止盈止损设置弹框-->
		<Popup v-model:show="showWinSetting" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('trade.desc25')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="win-setting">
					<!-- <div class="action-btn flex">
						<div class="btn flex-center" :class="winForm.tradeType == 'buy' ? 'green' : ''" @click="changeWinType('buy')">做多</div>
						<div class="btn flex-center"  :class="winForm.tradeType == 'sell' ? 'red' : ''" @click="changeWinType('sell')">做空</div>
					</div> -->

					<div class="win-info" v-if="showWinInfo">
						<div class="info-line flex-center-between">
							<div class="label">{{i18n.t('trade.th10')}}</div>
							<div>{{parseFloat(winData.OpenClose).toFixed(4)}}</div>
						</div>
						<div class="info-line flex-center-between">
							<div class="label">{{i18n.t('trade.label21')}}</div>
							<div>{{bitInfo.count}}</div>
						</div>
						<div class="info-line flex-center-between">
							<div class="label">{{i18n.t('trade.th12')}}</div>
							<div>{{parseFloat(winData.Close).toFixed(4)}}</div>
						</div>
					</div>

					<div class="flex-center-between setting-title">
						<div>{{i18n.t('trade.label24')}}</div>
						
					</div>

					<div class="trade-money flex-center-between">
						<div class="left full-width flex-center-between">
							<div>
								<input type="number" inputmode="decimal" @input="checkWinMoney(1)" v-model="winForm.winMoney" :placeholder="i18n.t('trade.label26')">
							</div>
							<div class="flex-center icon">
								<img :src="minusUrl" alt="" @click="minusWinMoney(1)">
								<span class="space"></span>
								<img :src="addUrl2" alt="" @click="addWinMoney(1)">
							</div>
						</div>
						
					</div>

					<div class="flex percent-line">
						<div class="percent flex-center" v-for="(item, i) in percentList" :key="i" :class="winForm.winPercent == item ? 'active' : ''" @click="changePercent(item, 1)">{{item}}%</div>
					</div>

					<!---止损-->
					<div class="flex-center-between setting-title">
						<div>{{i18n.t('trade.label27')}}</div>
						
					</div>

					<div class="trade-money flex-center-between">
						<div class="left flex-center-between">
							<div>
								<input type="number" inputmode="decimal" @input="checkWinMoney(2)" v-model="winForm.loseMoney" :placeholder="i18n.t('trade.label28')">
							</div>
							<div class="flex-center icon">
								<img :src="minusUrl" alt="" @click="minusWinMoney(2)">
								<span class="space"></span>
								<img :src="addUrl2" alt="" @click="addWinMoney(2)">
							</div>
						</div>
						
					</div>

					<div class="flex percent-line">
						<div class="percent flex-center" v-for="(item, i) in losePercentList" :key="i" :class="winForm.losePercent == item ? 'active' : ''" @click="changePercent(item, 2)">{{item}}%</div>
					</div>

					<div class="desc-line">
						<div class="desc-title flex-center-between" @click="showDetail">
							<span>{{i18n.t('trade.desc26')}}</span>
							<img :src="downUrl2" alt="" :class="showWinDesc ? '' : 'hide'">
						</div>
						<div class="desc-info" :class="showWinDesc ? '' : 'hide'">
							{{i18n.t('trade.desc27')}}
						</div>
					</div>

					<div class="bottom-btn">
						<Button @click="ensureWin">{{i18n.t('trade.btn2')}}</Button>
					</div>
				</div>
			</div>
		</Popup>
		<!--看涨看跌-->
		<Popup v-model:show="showUpDownPopup" position="bottom" lock-scroll class="rate-popup">
			<div class="up-down-box">
				<div class="flex-center-between">
					<div class="title">
						<span >{{i18n.t('trade.label29')}} {{bitInfo.Name}} {{i18n.t('trade.label30')}}</span>
						<span class="label" :class="upDownType == 1 ? 'green' : 'red'">{{upDownType == 1 ? i18n.t('trade.label39') : i18n.t('trade.label40')}}</span>
					</div>
					<div @click="cancel" class="cancel">{{i18n.t('trade.btn1')}}</div>
				</div>

				<div class="time-box flex-wrap">
					<div class="time flex-center"  v-for="(item, i) in timeList" :key="i" @click="changeUpDownPercent(item)" :class="[upDownForm.time == item.Time ? 'active' : '', upDownType == 1 ? 'green' : 'red']">
						{{item.Time}} {{parseFloat(item.ProfitRatio)}}%
					</div>
				</div>

				<div class="flex-center-between info-line">
					<div class="label">{{i18n.t('trade.label31')}}</div>
					<div>{{i18n.t('trade.label32')}}: {{futuresObj.TrusteeFeeRatio}}%</div>
				</div>

				<div class="input-box">
					<input type="number" inputmode="decimal" v-model="upDownForm.money" :placeholder="`${i18n.t('trade.label33')} ${futuresObj.MinInvestment || ''} USDT`">
				</div>

				<div class="flex-center-between profit"> 
					<div class="flex-center">
						<span>{{i18n.t('trade.label34')}}</span>
						<span class="label">{{tradeType == 'buy' ? userOptionMoney : userBitOptionMoney}} USDT</span>
						<img :src="transferUrl2" alt="">
					</div>
					<div>
						{{i18n.t('trade.label35')}}：{{profitMoney}} USDT
					</div>
				</div>

				<div class="bottom-btn">
					<Button :class="upDownType == 1 ? 'green' : 'red'" :disabled="!upDownForm.money" @click="submitOption">{{i18n.t('trade.btn3')}}</Button>
				</div>
			</div>
		</Popup>

		<!--二次确认弹框-->
		<Popup v-model:show="showEnsureModal" position="center" lock-scroll class="ensure-popup">
			<div class="ensure-modal flex-column-center">
				<div class="icon">
					<img :src="warningUrl" alt="">
				</div>
				<div class="title">{{i18n.t('trade.desc28')}}</div>
				<div class="flex-center">
					<Button class="gray" @click="cancel">{{i18n.t('trade.btn1')}}</Button>
					<Button @click="ensureAction">{{i18n.t('trade.btn2')}}</Button>
				</div>
			</div>
		</Popup>
		<!--平仓-->
		<Popup v-model:show="showClearPage" position="bottom" lock-scroll class="rate-popup">
			<clear-page :bitInfo="bitInfo" :orderData="orderdetailData" @cancel="cancel" @submit="submitClear"></clear-page>
		</Popup>

		<!--下单确认-->
		<Popup v-model:show="showConfirmModal" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div class="flex-center">
						<span :class="tradeType == 'buy' ? 'green' : 'red'" v-if="ensureType == 'spot'">
							{{i18n.t(`trade.tab${actionType}`)}}
							{{tradeType == 'buy' ? i18n.t('trade.buy1') : i18n.t('trade.sell1')}}
						</span>
						<span :class="ensureData.type == 'long' ? 'green' : 'red'" v-if="ensureType == 'contract'">
							{{i18n.t(`trade.tab${actionType}`)}}
							{{ensureData.type == 'long' ? i18n.t('trade.buy2') : i18n.t('trade.sell2')}}
						</span>
						<span class="small">{{bitInfo.Name}}</span>
					</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>

				<div class="info-line flex-center-between mt-15" v-if="ensureType == 'spot' && actionType == 3">
					<div>{{i18n.t('trade.label5')}}</div>
					<div class="value">{{form.stopPrice}} USDT</div>
				</div>
				<div class="info-line flex-center-between mt-15">
					<div>{{i18n.t('trade.label4')}}</div>
					<div class="green">{{ensureData.close}} USDT</div>
				</div>
				<div class="info-line flex-center-between mt-15">
					<div>{{i18n.t('trade.label42')}}</div>
					<div class="value">{{ensureData.number}} {{bitInfo.Name}}</div>
				</div>
				<div class="info-line flex-center-between mt-15">
					<div>{{i18n.t('trade.label8')}}</div>
					<div class="value">{{ ensureData.total}} USDT</div>
				</div>
				<div class="info-line flex-center-between mt-15" v-if="ensureType == 'contract'">
					<div>{{i18n.t('trade.label43')}}</div>
					<div class="value">{{multForm.fullType == 'full' ? i18n.t('trade.label1') : i18n.t('trade.label2')}} {{multForm.multiple}}X</div>
				</div>
				<div class="info-line flex-center-between mt-15" v-if="ensureType == 'contract'">
					<div>{{i18n.t('trade.label24')}}</div>
					<div class="value">{{winForm.winMoney || '- -'}}</div>
				</div>
				<div class="info-line flex-center-between mt-15" v-if="ensureType == 'contract'">
					<div>{{i18n.t('trade.label27')}}</div>
					<div class="value">{{winForm.loseMoney || '- -'}}</div>
				</div>
				<div class="info-line flex-center-between mt-15" v-if="ensureType == 'contract'">
					<div>{{i18n.t('trade.th12')}}</div>
					<div class="yellow">- - USDT</div>
				</div>
				<div class="info-line flex-center-between mt-15" v-if="ensureType == 'contract'">
					<div>{{i18n.t('trade.label44')}}</div>
					<div class="value">{{i18n.t('trade.label45')}}</div>
				</div>

				<div class="modal-btn mt-30">
					<Button v-if="ensureType == 'spot'" :class="tradeType == 'buy' ? 'green' : 'red'" @click="ensureSubmit">{{tradeType == 'buy' ? i18n.t('trade.buy1') : i18n.t('trade.sell1')}} {{bitInfo.Name}}</Button>
					<Button v-if="ensureType == 'contract'" :class="ensureData.type == 'long' ? 'green' : 'red'" @click="ensureSubmit">{{i18n.t('common.ensure')}}</Button>
				</div>
			</div>
		</Popup>

		<setting-page v-show="showSetting" @back="cancel" @change="changeSetting"></setting-page>
		<all-order v-if="showAllOrder" @back="cancel" :bitInfo="bitInfo"></all-order>
  </div>
</template>

<script>
import { Button, Search, List, Slider, Popup, Picker, Checkbox, Form } from 'vant';
import settingPage from './setting'
import allOrder from './allOrder'
import chartPage from './chart'
import bitPage from '@/components/bit'
import clearPage from './clear'
import { initWebSocket } from '@/utils/common'
import { getToken } from '@/utils/auth'
import { getCoinList } from '@/api/index'
import { userInfo } from '@/api/user'
import { wallet, allTrades } from '@/api/assest'
import { spotBuy, futuresSetting, optionOrder, optionOrderList, spotList, cancelSpotOrder, contractOrder, contractList, tradeHistory, contractSpot, contractLiquidation } from '@/api/trade'
export default {
  name: 'about',
  components: {
    Button,
    Search,
    List,
		Slider,
		Popup,
		Picker,
		Checkbox,
		settingPage,
		bitPage,
		allOrder,
		chartPage,
		clearPage
  },
	computed: {
		profitMoney() {
			if (!this.upDownForm.money) {
				return '0.0000'
			} else {
				return (this.upDownForm.money * this.upDownForm.percent / 100).toFixed(4)
			}
		},
		isSend() {
      return this.$store.state.isSend
    },
		bitList() {
			return this.$store.state.bitList
		},
	},
  data() {
    return {
			form: {
				dealPrice: '',
				triggerType: 1,
				triggerTypeName: this.i18n.t('trade.label25'),
				limitType: 1,
				limitTypeName: this.i18n.t('trade.label22'),
				setting1: false,
				setting2: false,
				number: '',
				totalMoney: '',
				stopName: this.i18n.t('trade.label24'),
				stop: '1',
				stopPrice: ''
			},
			newestMoney: 0,
			winForm: {
				tradeType: 'buy',
				winMoney: '',
				loseMoney: '',
				winPercent: '',
				losePercent: ''
			},
			upDownForm: {
				time: '',
				percent: '',
				money: ''
			},
			multForm: {
				fullType: 'full',
				multiple: 10,
			},
			multList: [10, 20, 50, 100, 200],
			userMoney: '0.000000',
			userContractMoney: '0.000000',
			userOptionMoney: '0.000000',
			userBitMoney: '0.000000',
			userBitContractMoney: '0.000000',
			userBitOptionMoney: '0.000000',
			percentList: [10, 20, 50, 100, 150],
			losePercentList: [10, 20, 30, 50, 90],
			bitUrl: require('../../assets/imgs/icon-bit.png'),
			moreUrl: require('../../assets/imgs/icon-more.png'),
			addUrl: require('../../assets/imgs/icon-add.png'),
			addUrl2: require('../../assets/imgs/icon-add2.png'),
			addUrl3: require('../../assets/imgs/icon-add3.png'),
			minusUrl: require('../../assets/imgs/icon-minus.png'),
			downUrl: require('../../assets/imgs/icon-arrow-down.png'),
			downUrl2: require('../../assets/imgs/icon-arrow-down2.png'),
			downUrl3: require('../../assets/imgs/icon-arrow-down3.png'),
			defaultUrl: require('../../assets/imgs/icon-default.png'),
			buyUrl: require('../../assets/imgs/icon-buy.png'),
			saleUrl: require('../../assets/imgs/icon-sale.png'),
			orderUrl: require('../../assets/imgs/icon-order.png'),
			rechargeUrl: require('../../assets/imgs/icon-recharge.png'),
			transferUrl: require('../../assets/imgs/icon-transfer.png'),
			transferUrl2: require('../../assets/imgs/icon-transfer2.png'),
			lineUrl: require('../../assets/imgs/icon-line.png'),
			helpUrl: require('../../assets/imgs/icon-help.png'),
			closeUrl: require('../../assets/imgs/icon-close.png'),
			chartUrl: require('../../assets/imgs/icon-chart.png'),
			emptyUrl: require('../../assets/imgs/order-empty.png'),
			warningUrl: require('../../assets/imgs/icon-warning2.png'),
			tradeType: 'buy', // 买入、卖出
			actionTypeName: this.i18n.t('trade.tab1'), // 交易方式
			actionType: 1,
			tradeMoney: '',//订单价格
			moneySpace: 0.01, // 每次增减金额
			maxSize: 2, // 订单价格小数最大位数
			actionTypeList: [
				{
					value: 1,
					text: this.i18n.t('trade.tab1')
				},
				{
					value: 2,
					text: this.i18n.t('trade.tab2')
				},
				{
					value: 3,
					text: this.i18n.t('trade.tab3')
				},
				// {
				// 	value: 4,
				// 	text: '条件单'
				// },
			],
			triggerTypeList: [
				{
					value: 1,
					text: this.i18n.t('trade.label24')
				},
				{
					value: 2,
					text: this.i18n.t('trade.label27')
				},
			],
			limitTypeList: [
				{
					value: 1,
					text: this.i18n.t('trade.label22')
				},
				{
					value: 2,
					text: this.i18n.t('trade.label21')
				}
			],
			bitInfo: {
				Name: 'BTC',
				ID: '1',
				percent: 0,
				count: '--'
			},
			tradeMoneyPercent: 0,
			tabIndex: 0,
			tabList: [this.i18n.t('trade.tab4'), this.i18n.t('trade.tab5'), this.i18n.t('trade.tab6')],
			showDefault: true,
			showPercent: false,
			showPickerModal: false,
			showPickerModal2: false,
			showPickerModal3: false,
			showMoreModal: false,
			showUpDownPopup: false,
			showMultModal: false,
			showEnsureModal: false,
			ensureType: '',
			ensureParam: '',
			pickerTop: 0,
			pickerTop2: 0,
			pickerTop3: 0,
			moreTop: 0,
			layout: 0,
			upDealList: [],
      upDealListBak: [],
			allUpDealList: [],
      downDealList: [],
      downDealListBak: [],
			allDownDealList: [],
			deepName: 0.001,// 展示深度
			deepType: 1, // 展示方式
			afterSize: 3, // 展示价格小数位数
			maxValue: 1400, // 展示数量最大值
			minValue: 500, // 展示数量最小值
			deepList: [1, 0.1, 0.01, 0.001],
			typeList: [
				{
					title: this.i18n.t('trade.label36'),
					type: 1,
				},
				{
					title: this.i18n.t('trade.label37'),
					type: 2,
				},
				{
					title: this.i18n.t('trade.label38'),
					type: 3,
				},
			],
			showPopup: false,
			pickerType: '',
			orderTabIndex: 1,
			showAll: false,
			showSetting: false,
			showAllOrder: false,
			showRate: false,
			showBit: false,
			showWinSetting: false,
			showWinInfo: false,
			showWinDesc: false,
			showConfirmModal: false,
			upDownType: '',
			timeList: [],
			webSocket: null,
			futuresObj: {},
			orderList: [],
			contractOrderList: [],
			optionOrderArr: [],
			isOrderEnsure: true,
			isCancelOrderEnsure: true,
			tradeDataBak: [], // 存放临时交易数据
			timer: '',
			isInitTrade: false, // 防止接口过慢
			isLogin: true,
			setDeep: false,
			upPercentList: [],
			downPercentList: [],
			walletBitList: [],
			allTradesData: [],
			orderTimer: '',
			showClearPage: false,
			orderdetailData: {},
			ensureForm: null,
			ensureData: {}
    }
  },
	beforeDestroy() {
    this.$store.state.isSend = false
		if (this.webSocket && this.webSocket.close) {
			this.$store.state.isClose = true
      this.webSocket.close()
    }
		clearInterval(this.timer)
		clearTimeout(this.orderTimer)
  },
  mounted() {
		let name = this.$route.query.name
		if (name) {
			this.bitInfo.Name = name
			this.bitInfo.ID = this.$route.query.id
		}
		this.initAllTrade()
		this.initSocket()
		this.init()
		this.initTrade()

		this.initFuture()
		this.initOrder()

		this.initConfirm()
		

		// this.isOrderEnsure = localStorage.getItem('isOrderEnsure') === 'true'
		// this.isCancelOrderEnsure = localStorage.getItem('isCancelOrderEnsure') === 'true'

		this.isLogin = getToken()
		
  },
  methods: {
		init() {
			if (this.bitList.length == 0) {
        getCoinList().then(res => {
          let bitListBak = res.data.Items
          this.$store.state.bitList = [].concat(bitListBak)
					let index = this.bitList.findIndex(v => {
						return v.Name == this.bitInfo.Name
					})
					if (index > -1) {
						this.bitInfo = this.bitList[index]
						this.tradeMoney = this.bitInfo.count
					}
        })
      } else {
				let index = this.bitList.findIndex(v => {
					return v.Name == this.bitInfo.Name
				})
				if (index > -1) {
					this.bitInfo = this.bitList[index]
					this.tradeMoney = this.bitInfo.count
				}
			}
			//用户余额
			this.initWallet()
		},
		initConfirm() {
			userInfo().then(res => {
				this.isOrderEnsure = res.data.OrderConfirm == 1
				this.isCancelOrderEnsure = res.data.CancelConfirm == 1
			})
		},
		initAllTrade() {
      allTrades().then(res => {
        this.allTradesData = res.data
      })
    },
		initWallet() {
			if (getToken()) {
				this.initBitMoney()

				let form = new FormData()
				// form.append('CurrencyId', 1)
				form.append('current', 1)
				form.append('pageSize', 10)
				form.append('name', 'usdt')
				wallet(form).then(res => {
					let data = res.data.Items[0]
					if (data) {
						this.userMoney = data.SpotUse
						this.userOptionMoney = data.OptionUse
						this.userContractMoney = data.ContractUse
					}
				})

			}
		},
		initBitMoney() {
			let form = new FormData()
			form.append('CurrencyId', this.bitInfo.ID)
			form.append('current', 1)
			form.append('pageSize', 10)
			wallet(form).then(res => {
				let data = res.data.Items[0]
				this.walletBitList =  res.data.Items
				if (data) {
					this.userBitMoney = data.SpotUse
					this.userBitOptionMoney = data.OptionUse
					this.userBitContractMoney = data.ContractUse
				}
			})
		},
		initTrade() {
			tradeHistory({
				ID: parseInt(this.bitInfo.ID,10),
				limit: 20
			}).then(res => {
				if (res.data) {
					this.isInitTrade = true
					this.initDealListBak(res.data)
				}
			})
		},
		initFuture() {
			futuresSetting().then(res => {
				this.timeList = res.data.Items
				let data = this.timeList[0]
				this.futuresObj = data
				this.upDownForm.time = data.Time
				this.upDownForm.percent = data.ProfitRatio
			})
		},
		initOrder() {
			if (!getToken()) {
				return
			}
			clearTimeout(this.orderTimer)
			
			let form = new FormData()
			form.append('current', 1)
			form.append('pageSize', 20)
			if (this.tabIndex == 0) {
				spotList(form).then(res => {
					this.orderList = res.data.Items
					this.orderTimer = setTimeout(() => {
						this.initOrder()
					}, 3000)
				})
			} else if (this.tabIndex == 1) {
				contractList(form).then(res => {
					this.contractOrderList = res.data.Items
					this.orderTimer = setTimeout(() => {
						this.initOrder()
					}, 3000)
				})
			} else if (this.tabIndex == 2) {
        this.optionOrderArr = []
				optionOrderList(form).then(res => {
					this.optionOrderArr = res.data.Items
					this.orderTimer = setTimeout(() => {
						this.initOrder()
					}, 3000)
				})
			}
		},
		async initSocket(){ //初始化weosocket
      let client = await initWebSocket(-1, this.websocketonmessage, this.initSocket)
      this.webSocket = client
			this.timer = setInterval(() => {
				this.clearTradeData()
			}, 1000)
    },
		websocketonmessage(e){ //数据接收
      let data = e.data
      let redata = {}
			if (data.indexOf('Welcome') > -1) {
				console.log(1111, '订阅')
				this.webSocket.send(JSON.stringify({
					action: 'subscribe',
					topic: `${this.bitInfo.Name}-USDT-trades`,
					message: ''
				}))
				//订阅k线数据
				this.webSocket.send(JSON.stringify({
					action: 'subscribe',
					topic: `${this.bitInfo.Name}-USDT`,
					message: ''
				}))
			}
      try {
        redata = JSON.parse(e.data)
        this.setListData(redata)
      } catch(e) {
      }
    }, 
		setListData(data) {
			let Amount = data.fields.Amount
			if (!Amount) { //行情
				let index = this.bitList.findIndex(v => {
					return v.Name + '-USDT' == data.tags.InstId
				})
				let valObj = data.fields
				let percent = parseFloat(((valObj.Close - valObj.Yesterday) / valObj.Yesterday * 100).toFixed(2))
				if (index > -1) {
					
					let bitListBak = [].concat(this.bitList)
					bitListBak[index] = {
						...bitListBak[index],
						...valObj,
						percent,
						count: valObj.Close,
					}
					this.$store.state.bitList = [].concat(bitListBak)
					if (this.bitInfo.Name + '-USDT' == data.tags.InstId) {
						this.bitInfo = bitListBak[index]
						if (this.showDefault) {
							if(this.tradeMoney === '' || this.tradeMoney === undefined) {
								this.tradeMoney = this.bitInfo.count
							}
						}
						
						//计算深度列表
						if (!this.setDeep ) {
							this.getDeepList(valObj.Close)
							this.setDeep = true
						}
					}
					this.newestMoney = parseFloat(valObj.Close)
					// if (this.form.limitType == 2 && this.actionType == 3) {
					// 	this.changeMoney(false, true)
					// }
				} else {
					this.bitInfo.percent = percent
					this.bitInfo.count = valObj.Close
					this.newestMoney = parseFloat(valObj.Close)
				}
			} else {//交易量
				this.isInitTrade = true
				if ((this.bitInfo.Name + '-USDT') == data.tags.InstId) {
					
					let type = data.fields.Side
					let param = {
						value: data.fields.Price,
						val: parseFloat(data.fields.Price),
						dealCount: this.resetCount(parseFloat(data.fields.Amount)),
						count: data.fields.Amount,
						time: new Date().getTime(),
						type: type
					}
					this.tradeDataBak.push(param)
					let totalList = this.tradeDataBak.filter(v => {
						return v.value == param.value && v.type == param.type
					})
					let total = 0
					totalList.forEach(v => {
						total += parseFloat(v.count)
					})
					total = parseFloat(total.toFixed(8))
					let newParam = {
						...param,
						dealCount: this.resetCount(total),
						count: total,
						time: new Date().getTime(),
					}
					//价格从高到低排列
					if (type == 'Sell') {
						let index = this.allUpDealList.findIndex(v => {
							return v.value == data.fields.Price
						})
						if (index > -1) {
							this.allUpDealList.splice(index, 1, newParam)
						} else {
							this.allUpDealList.push(newParam)
						}
						
						this.allUpDealList.sort((a, b) => {
							return b.val - a.val
						})
					} else {
						let index = this.allDownDealList.findIndex(v => {
							return v.value == data.fields.Price
						})
						if (index > -1) {
							this.allDownDealList.splice(index, 1, newParam)
						} else {
							this.allDownDealList.push(newParam)
						}
						this.allDownDealList.sort((a, b) => {
							return b.val - a.val
						})
					}

					this.upDealListBak = this.allUpDealList.filter(v => {
						return v.val >= parseFloat(this.bitInfo.count)
					})
					this.downDealListBak = this.allDownDealList.filter(v => {
						return v.val <= parseFloat(this.bitInfo.count)
					})

					if (this.upDealListBak.length < 6) {
						this.upDealListBak = this.allUpDealList.slice(0, 6)
					}
					if (this.downDealListBak.length < 6) {
						this.downDealListBak = this.allDownDealList.slice(this.allDownDealList.length - 6)
					}

					
					// //过滤3秒前数据
					// this.upDealListBak = this.upDealListBak.filter((v, i) => {
					// 	return new Date().getTime() - v.time < 3000 || (v.val >= parseFloat(this.bitInfo.count))
					// })
					// this.downDealListBak = this.downDealListBak.filter((v, i) => {
					// 	return new Date().getTime() - v.time < 3000 || (v.val <= parseFloat(this.bitInfo.count))
					// })
					// //有效单数较少时，用默认行填充
					// if (this.upDealListBak.length < 16) {
					// 	let size = 16 - this.upDealListBak.length
					// 	let emptyArr = []
					// 	for(let i = 0; i < size; i++) {
					// 		emptyArr.push({
					// 			value: '--',
					// 			val: 0,
					// 			dealCount: '--',
					// 			count: 0
					// 		})
					// 	}
					// 	this.upDealListBak = emptyArr.concat(this.upDealListBak)
					// }
					if (this.downDealListBak.length < 16) {
						let size = 16 - this.downDealListBak.length
						let emptyArr = []
						for(let i = 0; i < size; i++) {
							emptyArr.push({
								value: '--',
								val: 0,
								dealCount: '--',
								count: 0
							})
						}
						this.downDealListBak = this.downDealListBak.concat(emptyArr)
					}
					this.initDealList()
				}
			} 
		},
		clearTradeData() {
			let now = new Date().getTime()
			//保留3s内数据
			this.tradeDataBak = this.tradeDataBak.filter(v => {
				return v.time && now - v.time < 3000
			})
		},
		getDeepList(price) {
			let str = price + ''
			let afterSize = str.length - str.indexOf('.') - 1
			if (afterSize < 2) {
				afterSize = 2
			}
			let list = []
			for(let i = 0; i < 4; i++) {
				let val = 10 / Math.pow(10, afterSize - 2 + i)
				if (val < 1) {
					val = val.toFixed(afterSize - 3 + i)
				}  
				list.push(val)
			}
			this.deepList = list
			this.deepName = this.deepList[3] + ''
			this.afterSize = this.deepName.length - this.deepName.indexOf('.') - 1
		},
		toPath(path) {
			this.$router.push({
				name: path
			})
		},
    changeTab(i) {
			this.tabIndex = i
			this.orderTabIndex = 1
			this.initDealList()
			this.initOrder()
		},
    changeType(type) {
			this.tradeType = type
			this.tradeMoneyPercent = 0
			this.form.number = ''
			this.form.totalMoney = ''
			//刷新钱包数据
			this.initWallet()
		},
		dragStart() {
			this.showPercent = true
		},
		dragEnd() {
			this.showPercent = false
		},
		checkMoney() {
			let newMoney = parseFloat(this.tradeMoney)
			this.tradeMoney = parseFloat((newMoney).toFixed(this.maxSize))
		},
		addMoney() {
			let newMoney = this.moneySpace + parseFloat(this.tradeMoney) || 0
			this.tradeMoney = parseFloat(newMoney.toFixed(2)).toFixed(2)
			this.changeMoney()
		},
		minusMoney() {
			let newMoney =  parseFloat(this.tradeMoney) - this.moneySpace || 0
			if (newMoney < 0) {
				newMoney = 0
			}
			this.tradeMoney = parseFloat(newMoney.toFixed(2)).toFixed(2)
			this.changeMoney()
		},
		showPicker() {
			let scrollTop = document.querySelector('.trade-page').scrollTop
			let dom = document.querySelector('.trade-type')
			let top = dom.offsetTop
			this.pickerTop = top - scrollTop
			this.showPickerModal = true
		},
		showPicker2() {
			let scrollTop = document.querySelector('.trade-page').scrollTop
			let dom = document.querySelector('.trigger-dom')
			let top = dom.offsetTop
			this.pickerTop2 = top - scrollTop
			this.showPickerModal2 = true
		},
		showPicker3() {
			let scrollTop = document.querySelector('.trade-page').scrollTop
			let dom = document.querySelector('.limit-dom')
			let top = dom.offsetTop
			this.pickerTop3 = top - scrollTop
			this.showPickerModal3 = true
		},
		showMore() {
			let scrollTop = document.querySelector('.trade-page').scrollTop
			let dom = document.querySelector('.trade-info')
			let top = dom.offsetTop
			this.moreTop = top - scrollTop
			if (this.moreTop < -10) {
					this.moreTop = -10
			}
			this.showMoreModal = true
		},
		changeActionType(data) {
			this.actionType = data.value
			this.actionTypeName = data.text
			this.showPickerModal = false
			this.initDealList()
			if (data.value == 1 || data.value == 3) {
				this.showDefault = true
				this.tradeMoney = this.bitInfo.count
			}
		},
		changeTriggerType(data) {
			this.form.stop = data.value
			this.form.stopName = data.text
			this.showPickerModal2 = false
		},
		changeLimitType(data) {
			this.form.limitType = data.value
			this.form.limitTypeName = data.text
			this.showPickerModal3 = false
			this.tradeMoney = this.bitInfo.count
			this.showDefault = true
			this.changeMoney()
		},
		cancel() {
			this.showPickerModal = false
			this.showPickerModal2 = false
			this.showPickerModal3 = false
			this.showMoreModal = false
			this.showSetting = false
			this.showRate = false
			this.showBit = false
			this.showWinInfo = false
			this.showWinSetting = false
			this.showPopup = false
			this.showAllOrder = false
			this.showUpDownPopup = false
			this.showMultModal = false
			this.showEnsureModal = false
			this.showClearPage = false
			this.showConfirmModal = false
		},
		showDeepPicker() {
			this.showPopup = true
			this.pickerType = 'deep'
		},
		showTypePicker() {
			this.showPopup = true
			this.pickerType = 'type'
		},
		onConfirm() {
			this.showPopup = false
		},
		initDealList() {
			let size = 12
      switch(this.actionType) {
        case 1: size = 12; break;
        case 2: size = 8; break;
        case 3: size = 14; break;
        case 4: size = 16; break;
        default: break
      }
			if (this.tabIndex == 1) {
				size -= 2
			}
			let upBeginSize = 0
			if (this.deepType == 1) {
				upBeginSize = this.upDealListBak.length - size / 2
			} else {
				upBeginSize = this.upDealListBak.length - size
			}
			if (upBeginSize < 0) {
				upBeginSize = 0
			}
      switch(this.deepType) {
        case 1: this.upDealList = this.upDealListBak.slice(upBeginSize, this.upDealListBak.length); this.downDealList = this.downDealListBak.slice(0,  size / 2); break;
        case 2: this.upDealList = []; this.downDealList = this.downDealListBak.slice(0, size); break;
        case 3: this.upDealList = this.upDealListBak.slice(upBeginSize, this.upDealListBak.length); this.downDealList = []; break;
        default: break
      }
			// this.upDealList.reverse()
    },
		checkPrice(data) {
			// if (this.form.limitType == 2) {
			// 	return
			// }
			this.tradeMoney = this.resetValue(data.value)
      this.form.dealPrice = this.resetValue(data.value)
			this.showDefault = false
			if (this.form.number) {
				this.changeCount()
			}
    },
		initDealListBak(data) {
			let buyList = data.buy
			let sellList = data.sell
			buyList.forEach(v => {
				let index = this.allUpDealList.findIndex(val => {
					return v.Price == val.value
				})
				if (index === -1) {
					this.allUpDealList.push({
						value: v.Price,
						val: parseFloat(v.Price),
						dealCount: this.resetCount(parseFloat(v.Amount)),
						count: parseFloat(v.Amount)
					})
				}
			})
			sellList.forEach(v => {
				let index = this.allDownDealList.findIndex(val => {
					return v.Price == val.value
				})
				if (index === -1) {
					this.allDownDealList.push({
						value: v.Price,
						val: parseFloat(v.Price),
						dealCount: this.resetCount(parseFloat(v.Amount)),
						count: parseFloat(v.Amount)
					})
				}
			})

			this.allUpDealList.sort((a, b) => {
				return b.val - a.val
			})
			this.allDownDealList.sort((a, b) => {
				return b.val - a.val
			})

			this.upDealListBak = this.allUpDealList.filter(v => {
				return v.val >= parseFloat(this.bitInfo.count)
			})
			this.downDealListBak = this.allDownDealList.filter(v => {
				return v.val <= parseFloat(this.bitInfo.count)
			})

			if (this.upDealListBak.length < 6) {
				this.upDealListBak = this.allUpDealList.slice(0, 6)
			}
			if (this.downDealListBak.length < 6) {
				this.downDealListBak = this.allDownDealList.slice(this.allDownDealList.length - 6)
			}
			this.initDealList()
    },
		getSpace() {
			let space = this.deepName
			return space
		},
		resetValue(val) {
			if (isNaN(val)) {
				return val
			} else {
				if (this.afterSize > 0) {
					return parseFloat(val).toFixed(this.afterSize)
				} else if (this.afterSize == 0) {
					return parseInt(val)
				} else {
					return parseInt(val / Math.pow(10, -this.afterSize)) * Math.pow(10, - this.afterSize)
				}
			}
		},
		resetCount(value) {
			let size = 5
			if (value > 10000000) {
				let newNum = value / 1000 / 1000
				return newNum.toFixed(2) + 'M'
			} else if (value > 10000) {
				let newNum = value / 1000
				return newNum.toFixed(2) + 'K'
			} else if (value > 1) {
				let numStr = value + ''
				let beforeSize = numStr.substring(0, numStr.indexOf('.')).length
				let afterSize = size - beforeSize
				return value.toFixed(afterSize)
			} else {
				return parseFloat(value)
			}
		},
		getBgWidth(val, index, type) {
			let data = type == 1 ? this.upPercentList : this.downPercentList
			let dataBak = data[index] 
			let max = 100
			if (type == 1) {
				max = 100 - index * 10
			} else {
				max = 50 + index * 10
			}
			if (max > 100) {
				max = 100
			}
			
			if (!dataBak) {
				let percent = Math.random() * max
				data[index] = {
					val: val,
					width: percent
				}
				return `width: ${val ? percent : 0}%;`
			} else {
				if (val != dataBak.val) {
					let percent = Math.random() * max
					data[index] = {
						val: val,
						width: percent
					}
					return `width: ${val ? percent : 0}%;`
				} else {
					return `width: ${dataBak.width}%;`
				}
			}
		},
		changeDeep(val) {
			this.deepName = val
			let str = val + ''
			if (str.indexOf('.') > -1) {
				this.afterSize = str.length - str.indexOf('.') - 1
			} else {
				this.afterSize = 1 - str.length
			}
			this.showPopup = false
		},
		changeDeepType(data) {
			this.deepType = data.type
			this.showPopup = false
			this.initDealList()
		},
		changeOrderTab(i) {
			this.orderTabIndex = i
		},
		toInfo(type) {
			if (type == 'trade') {
				this.showSetting = true
			} else {
				this.showRate = true
			}
			this.showMoreModal = false
		},
		changeBit() {
			this.showBit = true
			
		},
		chooseBit(data) {
			//取消之前订阅交易量数据
			this.webSocket.send(JSON.stringify({
				action: 'unsubscribe',
				topic: `${this.bitInfo.Name}-USDT-trades`,
				message: ''
			}))
			this.webSocket.send(JSON.stringify({
				action: 'unsubscribe',
				topic: `${this.bitInfo.Name}-USDT`,
				message: ''
			}))
			//清空交易数据
			this.upDealListBak = []
			this.downDealListBak = []
			this.allDownDealList = []
			this.allUpDealList = []
			
			this.bitInfo = data
			this.tradeMoney = this.bitInfo.count
			this.showDefault = true
			this.showBit = false
			this.isInitTrade = false
			this.initTrade()
			this.init()

			//重新订阅交易量数据
			this.webSocket.send(JSON.stringify({
				action: 'subscribe',
				topic: `${this.bitInfo.Name}-USDT-trades`,
				message: ''
			}))
			this.webSocket.send(JSON.stringify({
				action: 'subscribe',
				topic: `${this.bitInfo.Name}-USDT`,
				message: ''
			}))
			this.setDeep = false
		},
		changeWinSetting() {
			this.showWinSetting = true
			// this.winForm.winMoney = (this.bitInfo.count * 1.1).toFixed(this.maxSize)
			// this.winForm.loseMoney = (this.bitInfo.count * 0.9).toFixed(this.maxSize)
		},
		saveWinSetting() {
			this.showBit = false
		},
		changeWinType(type) {
			this.winForm.tradeType = type
		},
		changeFullType(type) {
			this.multForm.fullType = type
		},
		changeMultiple(mult) {
			this.multForm.multiple = mult
		},
		checkWinMoney(type) {
			if (type == 1) {
				let newMoney = parseFloat(this.winForm.winMoney)
				this.winForm.winMoney = parseFloat((newMoney).toFixed(this.maxSize))
			} else {
				let newMoney = parseFloat(this.winForm.loseMoney)
				this.winForm.loseMoney = parseFloat((newMoney).toFixed(this.maxSize))
			}
		},
		addWinMoney(type) {
			if (type == 1) {
				let newMoney = this.moneySpace + parseFloat(this.winForm.winMoney) || 0
				this.winForm.winMoney = parseFloat(newMoney.toFixed(2)).toFixed(2)
				this.winForm.winPercent = ''
			} else {
				let newMoney = this.moneySpace + parseFloat(this.winForm.loseMoney) || 0
				this.winForm.loseMoney = parseFloat(newMoney.toFixed(2)).toFixed(2)
				this.winForm.losePercent = ''
			}
		},
		minusWinMoney(type) {
			if (type == 1) {
				let newMoney =  parseFloat(this.winForm.winMoney) - this.moneySpace || 0
				if (newMoney < 0) {
					newMoney = 0
				}
				this.winForm.winMoney = parseFloat(newMoney.toFixed(2)).toFixed(2)
				this.winForm.winPercent = ''
			} else {
				let newMoney =  parseFloat(this.winForm.loseMoney) - this.moneySpace || 0
				if (newMoney < 0) {
					newMoney = 0
				}
				this.winForm.loseMoney = parseFloat(newMoney.toFixed(2)).toFixed(2)
				this.winForm.losePercent = ''
			}
		},
		changePercent(val, type) {
			if (type == 1) {
				this.winForm.winPercent = val
				this.winForm.winMoney = (this.bitInfo.count * (1 + val / 100)).toFixed(this.maxSize)
			} else {
				this.winForm.losePercent = val
				this.winForm.loseMoney = (this.bitInfo.count * (1 - val / 100)).toFixed(this.maxSize)
			}
		},
		showDetail() {
			this.showWinDesc = !this.showWinDesc
		},
		showOrder() {
			if (!getToken()) {
				this.toPath('login')
				return
			}
			this.showAllOrder = true
		},
		showUpDownModal(type) {
			this.showUpDownPopup = true
			this.upDownType = type
			this.upDownForm.money = ''
			this.changeUpDownPercent(this.timeList[0])
		},
		changeUpDownPercent(data) {
			this.futuresObj = data
			this.upDownForm.time = data.Time
			this.upDownForm.percent = data.ProfitRatio
		},
		ensureSubmit() {
			this.showConfirmModal = false
			if (this.ensureType == 'spot') {
				this.doSubmit(this.ensureForm)
			} else if (this.ensureType == 'contract') {
				this.doSubmitContract(this.ensureForm)
			}
		},
		submit() {
			if (this.actionType != 2 && !this.form.number) {
				this.$store.state.warningMsg =  this.i18n.t('trade.error3')
				return
			}
			if ((this.actionType == 1 || this.actionType == 3 && this.form.limitType == 1) && !this.tradeMoney) {
				this.$store.state.warningMsg = this.i18n.t('trade.error4')
				return
			}
			if (this.actionType == 2 && this.tradeType == 'buy' && !this.form.totalMoney) {
				this.$store.state.warningMsg = this.i18n.t('trade.error5')
				return
			}
			if (this.actionType == 2 && this.tradeType == 'sell' && !this.form.number) {
				this.$store.state.warningMsg = this.i18n.t('trade.error3')
				return
			}
			if (this.actionType == 3 && !this.form.stopPrice) {
				this.$store.state.warningMsg = this.i18n.t('trade.error7')
				return
			}
			let Trust = ''
			let number = 0
			let close = 0
			let total = 0
			let stop = ''
			let stopPrice = 0
			if (this.actionType == 1) { // 限价交易
				Trust = 'limit'
				number = this.form.number
				total = this.form.totalMoney
				close = this.tradeMoney
			}
			if (this.actionType == 2) { // 市价交易
				Trust = 'market'
				total = this.form.totalMoney
				number = this.form.totalMoney / this.newestMoney
				close = this.newestMoney
			}
			if (this.actionType == 3) { // 止盈止损
				Trust = this.form.limitType == 1 ? 'limit' : 'market'
				stop = this.form.stop
				stopPrice = this.form.stopPrice
				number = this.form.number
				total = this.form.totalMoney
				close = this.tradeMoney
				// if (this.form.limitType == 1) {
				// } else {
				// 	total = this.form.totalMoney
				// 	number = this.form.totalMoney / this.newestMoney
				// 	close = this.newestMoney
				// }
			}
			let form = new FormData()
			form.append('Trust', Trust)
			form.append('CurrencyId', this.bitInfo.ID)
			form.append('Trade', this.tradeType)
			form.append('Number', number)
			form.append('Stop', stop)
			form.append('StopPrice', stopPrice)
			form.append('TrustNum', number)
			form.append('TrustClose', total)
			form.append('Close', close)


			if (this.isOrderEnsure) {
				this.ensureType = 'spot'
				this.showConfirmModal = true
				this.ensureForm = form
				this.ensureData = {
					total: total,
					number: number,
					close: close,
				}
			} else {
				this.doSubmit(form)
			}
		},
		
		doSubmit(form) {
			spotBuy(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('trade.success1')
					this.initOrder()
					this.initWallet()
					setTimeout(() => {
						this.initBitMoney()
					}, 1000)
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		},
		submitContract(type) {

			if (this.actionType != 2 && !this.form.number) {
				this.$store.state.warningMsg = this.i18n.t('trade.error3')
				return
			}
			if ((this.actionType == 1 || this.actionType == 3 && this.form.limitType == 1) && !this.tradeMoney) {
				this.$store.state.warningMsg = this.i18n.t('trade.error4')
				return
			}
			if (this.actionType == 2 && !this.form.totalMoney) {
				this.$store.state.warningMsg = this.i18n.t('trade.error5')
				return
			}
			let Trust = ''
			let number = 0
			let close = 0
			let total = 0
			let takeProfit = ''
			let stopLoss = ''
			if (this.actionType == 1) { // 限价交易
				Trust = 'limit'
				number = this.form.number
				// total = this.form.number * this.tradeMoney
				total = this.form.totalMoney
				close = this.tradeMoney
			}
			if (this.actionType == 2) { // 市价交易
				Trust = 'market'
				total = this.form.totalMoney
				number = this.form.totalMoney / this.newestMoney
				close = this.newestMoney
			}
			if (this.actionType == 3) { // 止盈止损
				Trust = this.form.limitType == 1 ? 'limit' : 'market'
				number = this.form.number
				// total = number * (this.form.limitType == 1 ? this.tradeMoney : this.newestMoney)
				total = this.form.totalMoney
				takeProfit = this.winForm.winMoney
				stopLoss = this.winForm.loseMoney
				close = this.tradeMoney
				// close = this.form.limitType == 1 ? this.tradeMoney : this.newestMoney
			}
			let form = new FormData()
			form.append('CurrencyId', this.bitInfo.ID)
			form.append('Trust', Trust)
			form.append('Direction', type)
			form.append('TrustNum', number)
			form.append('TrustClose', total)
			// form.append('TrustClose', this.form.totalMoney)
			form.append('Multiple', this.multForm.multiple)
			form.append('Full', this.multForm.fullType)
			form.append('TakeProfit', takeProfit)
			form.append('StopLoss', stopLoss)
			form.append('Close', close)

			if (this.isOrderEnsure) {
				this.ensureType = 'contract'
				this.showConfirmModal = true
				this.ensureForm = form
				this.ensureData = {
					type: type,
					total: total,
					number: number,
					close: close,
				}
			} else {
				this.doSubmitContract(form)
			}
		},
		doSubmitContract(form) {
			
			contractOrder(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('trade.success1')
					this.initOrder()
					this.initWallet()
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		},
		submitOption() {
			this.doSubmitOption()
			// if (this.isOrderEnsure) {
			// 	this.showEnsureModal = true
			// 	this.ensureType = 'option'
			// } else {
			// }
		},
		doSubmitOption() {
			if (!this.futuresObj) {
				this.$store.state.warningMsg = this.i18n.t('trade.error1')
				return
			}
			if (!this.upDownForm.money) {
				this.$store.state.warningMsg = this.i18n.t('trade.error2')
				return
			}
			let form = new FormData()
			form.append('CurrencyId', this.bitInfo.ID)
			form.append('Amount', this.upDownForm.money)
			form.append('Direction', this.upDownType == 1 ? 'rose' : 'fall')
			form.append('SettingId', this.futuresObj.ID)
			optionOrder(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('trade.success1')
					this.showUpDownPopup = false
					this.initOrder()
					this.initWallet()
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		},
		cancelOrder(data) {
			if (this.isCancelOrderEnsure) {
				this.showEnsureModal = true
				this.ensureParam = data
				this.ensureType = 'cancel'
			} else {
				this.doCancelOrder(data)
			}
		},
		doCancelOrder(data) {
			let form = new FormData()
			form.append('ID', data.ID)
			cancelSpotOrder(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('trade.success2')
					this.initOrder()
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		},
		ensureMult() {
			this.showMultModal = false
		},
		changeMultSetting() {
			this.showMultModal = true
		},
		ensureWin() {
			
			if (this.showWinInfo) {
				this.showWinInfo = false
				//更改止盈止损价格
				let form = new FormData()
				form.append('ID', this.winData.ID)
				form.append('TakeProfit', this.winForm.winMoney)
				form.append('StopLoss', this.winForm.loseMoney)
				contractSpot(form).then(res => {
					if (res.code == 0) {
						this.$store.state.successMsg = this.i18n.t('trade.success3')
						this.initOrder()
						this.showWinSetting = false
					} else {
						this.$store.state.errorMsg = res.msg
					}
				})
			} else {
				this.showWinSetting = false
			}
		},
		changeTotal() {
			if (this.tradeMoney) {
				this.form.number = parseFloat((this.form.totalMoney / this.tradeMoney).toFixed(6))
			} else {
				if (this.form.number) {
					this.tradeMoney =  parseFloat((this.form.totalMoney / this.form.number).toFixed(6))
				}
			}
		},
		changeCount() {
			if (this.tradeMoney) {
				this.form.totalMoney = parseFloat((this.tradeMoney * this.form.number).toFixed(6))
			} else {
				if (this.form.totalMoney) {
					this.tradeMoney = parseFloat((this.form.totalMoney / this.form.number).toFixed(6))
				}
			}
		},
		changeMoney(isInput, isMarket) {
			if (isMarket) {
				if (this.form.number) {
					this.form.totalMoney = parseFloat((this.newestMoney * this.form.number).toFixed(6))
				} else {
					if (this.form.totalMoney) {
						this.form.number = parseFloat((this.form.totalMoney / this.newestMoney).toFixed(6))
					}
				}
			} else {
				if (this.form.number) {
					this.form.totalMoney = parseFloat((this.tradeMoney * this.form.number).toFixed(6))
				} else {
					if (this.form.totalMoney) {
						this.form.number = parseFloat((this.form.totalMoney / this.tradeMoney).toFixed(6))
					}
				}
			}

			if (isInput) {
				this.showDefault = false
			}
		},
		changeSlide(val) {
			if (this.tradeType == 'buy') {
				let money = this.tabIndex == 0 ? this.userMoney : (this.tabIndex == 1 ? this.userContractMoney : this.userOptionMoney)
				this.form.totalMoney = money * (val / 100)
				if (this.tradeMoney) {
					this.form.number = parseFloat((this.form.totalMoney / this.tradeMoney).toFixed(6))
				} else {
					if (this.form.number) {
						this.tradeMoney =  parseFloat((this.form.totalMoney / this.form.number).toFixed(6))
					}
				}
			} else {
				let money = this.tabIndex == 0 ? this.userBitMoney : (this.tabIndex == 1 ? this.userBitContractMoney : this.userBitOptionMoney)
				this.form.number = money * (val / 100)
				if (this.tradeMoney) {
					this.form.totalMoney = parseFloat((this.tradeMoney * this.form.number).toFixed(6))
				} else {
					if (this.form.totalMoney) {
						this.tradeMoney =  parseFloat((this.form.totalMoney / this.form.number).toFixed(6))
					}
				}
			}
		},
		ensureAction() {
			this.showEnsureModal = false
			if (this.ensureType == 'cancel') {
				this.doCancelOrder(this.ensureParam)
			}
		},
		//设置止盈止损
		setWinOrLose(data) {
			this.showWinSetting = true
			this.showWinInfo = true
			this.winData = data
		},
		resetSpotMoney(count, id) {
      let index = this.allTradesData.findIndex(v => {
        return v.CurrencyId == id
      })
      if (index > -1) {
        let price = this.allTradesData[index].Price
        return parseFloat(price * count).toFixed(4)
      } else {
        return parseFloat(count).toFixed(4)
      }
    },
		toClearOrder(data) {
			this.orderdetailData = data
			this.showClearPage = true
		},
		submitClear(data) {
			let form = new FormData()
			form.append('ID', this.orderdetailData.ID)
			form.append('PlanTrust', data.type == 1 ? 'market' : 'limit')
			form.append('PlanTrustNum', data.number)
			form.append('PlanTrustPrice', data.price)
			contractLiquidation(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('trade.success3')
					this.showClearPage = false
					this.initOrder()
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		},
		changeSetting() {
			// this.isOrderEnsure = localStorage.getItem('isOrderEnsure') === 'true'
			// this.isCancelOrderEnsure = localStorage.getItem('isCancelOrderEnsure') === 'true'
		}
  },
}
</script>
<style lang="less">

</style>
