<template>
	<div class="recharge-page top-bar-page ">
		<top-bar :title="i18n.t('recharge.title')" class="black" parentId=".recharge-page" right-icon="log" @back="back"></top-bar>

		<div class="recharge-main">

			<div class="recharge-list">
				<!-- <div class="list flex-center" > -->
				<div class="list flex-center" @click="chooseRecharge">
					<div class="icon">
						<img :src="rechargeInfo.icon" alt="">
					</div>
					<div class="name flex-1">
						<span>{{rechargeInfo.name}}</span>
						<span class="desc">{{rechargeInfo.desc}}</span>
					</div>
					<div class="check">
						<img :src="rightUrl" alt="">
					</div>
				</div>
			</div>

			<div class="address-box">
				<div class="title">{{i18n.t('recharge.label1')}}</div>
				<div class="qrcode flex-center" >
					<div ref="qrcode" id="qrcode"></div>
				</div>
				<div class="address flex-center-between">
					<div class="info">{{address}}</div>
					<div class="flex-center label copy-icon" @click="copyData">
						<img :src="copyUrl" alt="">
						{{i18n.t('recharge.label2')}}
					</div>
				</div>
			</div>

			<div class="recharge-list mt-15" v-if="false">
				<div class="list flex-center" @click="changeAccount">
					<div class="name flex-1">
						<span>{{i18n.t('recharge.label3')}}</span>
					</div>
					<div class="check flex-center">
						<span class="label">{{accountName}}</span>
						<img :src="rightUrl" alt="">
					</div>
				</div>
			</div>
      <div class="recharge-list mt-15">
        <div class="auth-form">
          <div class="form-item">
            <div class="label">{{i18n.t('recharge.Amount')}}</div>
            <div class="input">
              <input type="tel" :placeholder="i18n.t('recharge.placeholder')" v-model="form.Amount">
            </div>
          </div>
        </div>
      </div>
      <div class="recharge-list mt-15">
        <div class="auth-form">
          <div class="form-item">
            <div class="label">{{i18n.t('recharge.PaymentVoucher')}}</div>
            <div  class="file-box flex-center-between">
              <div class="auth" style="width: 9.2rem">
                <div class="bg"  @click.stop="chooseFile(1)">
                  <img v-if="form.PaymentVoucher.length > 0" :src="'/upload/tmp/'+form.PaymentVoucher" alt="" >
                  <input type="file" class="hide-box" id="fileInput" @change="changeFile" accept="image/*">
                </div>
                <div class="text" style="width: 9.2rem" :class="form.PaymentVoucher ? 'active' : ''">
                  <span>{{i18n.t('recharge.Payment documents')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="auth-page" v-if="false">
        <div class="auth-main">
          <Button @click="submit">{{i18n.t('userAuth.btn')}}</Button>
        </div>
      </div>

			<div class="bottom-btn flex-center">
				<Button  class="plain" @click="submit">{{i18n.t('recharge.btn5')}}</Button>
				<Button class="copy-icon" @click="copyData">{{i18n.t('recharge.btn2')}}</Button>
			</div>

			<div class="recharge-warning">
				<div class="title">{{i18n.t('recharge.label4')}}</div>
				<div class="info">{{i18n.t('recharge.desc1')}}</div>
				<div class="info">{{i18n.t('recharge.desc2')}}</div>
				<div class="info">{{i18n.t('recharge.desc3')}}</div>
				<div class="info">{{i18n.t('recharge.desc4')}}</div>
			</div>
		</div>

     <!--充值类型-->
    <Popup v-model:show="showRecharge" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('recharge.label5')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list ">
          <div class="list flex-center" v-for="(item, i) in rechargeList" :key="i" @click="changeRecharge(item)">
            <div class="icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="name flex-1">
              <span>{{item.name}}</span>
              <span class="desc">{{item.desc}}</span>
            </div>
            <div class="check">
              <img :src="rechargeInfoBak.name == item.name ? radioUrl2 : radioUrl" alt="">
            </div>
          </div>
          <div class="btn">
            <Button  @click="submitChange">{{i18n.t('recharge.btn3')}}</Button>
          </div>
				</div>

			</div>
		</Popup>
		
		<!--选择账户-->
		<Popup v-model:show="showAccount" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('recharge.label6')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list ">
          <div class="list flex-center-between" :class="accountName == item.text ? 'active' : ''" v-for="(item, i) in accountList" :key="i" @click="chooseAccount(item)">
						<div>{{item.text}}</div>
						<div v-if="accountName == item.text">
							<img :src="checkUrl" alt="">
						</div>
					</div>
				</div>

			</div>
		</Popup>

		

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button } from 'vant'
import Clipboard from 'clipboard';
import QRCode from 'qrcodejs2'
import { rechargeAddress } from '@/api/assest'
import {rechargeApply, saveAssets, uploadFile, userIdentity} from '@/api/setting'
import html2canvas from 'html2canvas'
import { getAssets } from '@/utils/auth'
export default {
	components: {
    Popup,
    Button,
		topBar
  },
  data() {
		return {
			closeUrl: require('../../assets/imgs/icon-close.png'),
			copyUrl: require('../../assets/imgs/icon-copy.png'),
			backUrl: require('../../assets/imgs/icon-back.png'),
			rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
			logUrl: require('../../assets/imgs/icon-log.png'),
			radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
      checkUrl: require('../../assets/imgs/icon-checked2.png'),
			showRecharge: false,
			showAccount: false,
			rechargeAddressData: {},
			accountName: '',
      form:{
        Amount: '',
        PaymentVoucher: '',
      },
      rechargeList: [
				{
					icon: require('../../assets/imgs/bit1.png'),
					name: 'TRC20',
					desc: 'Tether USDT',
					key: 'USDT-TRC20'
				},
				{
					icon: require('../../assets/imgs/bit1.png'),
					name: 'ERC20',
					desc: 'Tether USDT',
					key: 'USDT-ERC20'
				},
				{
					icon: require('../../assets/imgs/bit2.png'),
					name: 'ETH',
					desc: 'Tether USDT',
					key: 'Ethereum'
				},
				{
					icon: require('../../assets/imgs/bit3.png'),
					name: 'BTC',
					key: 'Bitcoin'
				},
        {
          icon: require('../../assets/imgs/bit5.png'),
          name: 'USDC-TRC20',
          key: 'USDC-TRC20'
        },
        {
          icon: require('../../assets/imgs/bit5.png'),
          name: 'USDC-ERC20',
          key: 'USDC-ERC20'
        },
			],
			accountList: [
				{
					text: this.i18n.t('recharge.account1'),
					value: 'spot'
				},
				{
					text: this.i18n.t('recharge.account2'),
					value: 'option'
				},
				{
					text: this.i18n.t('recharge.account3'),
					value: 'contract'
				},
				{
					text: this.i18n.t('recharge.account4'),
					value: 'finance'
				},
			],
			rechargeInfo: {},
			rechargeInfoBak: {},
			address: '',
			addressData: {}
		}
	},
  mounted() {
		this.init()
		let type = this.$route.query.type || 'TRC20'
		let index = this.accountList.findIndex(v => {
			return v.value == getAssets()
		})
		if (index > -1) {
			this.accountName = this.accountList[index].text
		}

	

		let rechargeIndex = this.rechargeList.findIndex(v => {
			return v.name == type
		})
		if (rechargeIndex === -1) {
			rechargeIndex = 0
		}

		this.rechargeInfo = Object.assign({}, this.rechargeList[rechargeIndex])
  },
	methods: {
    chooseFile(type) {
      this.fileType = type
      document.getElementById('fileInput').value = ''
      document.getElementById('fileInput').click()
      console.log(222, type, this.fileType)
    },
		init() {
			rechargeAddress().then(res => {
				// console.log("res")
				this.rechargeAddressData = res.data
				let type = this.$route.query.type || 'TRC20'
				console.log("type")
				console.log(type)
				console.log(res.data)
				switch (type) {
					case "ETH":
						this.address = res.data['ETH-ERC20']
						break;
					case "BTC":
						this.address = res.data['Bitcoin']
						break;
					case "TRC20":
						this.address = res.data['USDT-TRC20']
						break;
					case "ERC20":
						this.address = res.data['USDT-ERC20']
						break;
          case "USDC-TRC20":
            this.address = res.data['USDC-TRC20']
            break;
          case "USDC-ERC20":
            this.address = res.data['USDC-ERC20']
            break;
				}
				// console.log(res.data)
				// this.address = res.data['USDT-TRC20'] || 'AJKDIJALSJDIJALSJDLJSADLDI'
				let qrcode = new QRCode(this.$refs.qrcode, {
					text: this.address,
					width: 200,
					height: 200,
					correctLevel: QRCode.CorrectLevel.H
				})
			})
    },
		back() {
			this.$router.go(-1)
		},
		toLog() {
			this.$router.push({
				name: 'assestLog',
				query: {
					type: 'recharge'
				}
			})
		},
		chooseRecharge() {
			this.rechargeInfoBak = Object.assign({}, this.rechargeInfo)
      this.showRecharge = true
    },
    changeRecharge(data) {
      console.log("changeRecharge")
      console.log(this.rechargeInfoBak)
			this.rechargeInfoBak = data
    },
		cancel() {
			this.showRecharge = false
		},
		copyData() {
      let data = this.address
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$store.state.successMsg = this.i18n.t('recharge.success')
    },
    changeFile(e) {
      let file = e.target.files[0]
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('userAuth.success1')
          this.form.PaymentVoucher = res.data
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    },
		submitChange() {
      console.log("submitChange")
			// this.init()
			this.rechargeInfo = Object.assign({}, this.rechargeInfoBak)
			console.log("rechargeInfo")
			console.log(this.rechargeInfo.name)
			console.log(this.rechargeAddressData )
			switch (this.rechargeInfo.name) {
				case "ETH":
					this.address = this.rechargeAddressData['ETH-ERC20']
					break;
				case "BTC":
					this.address = this.rechargeAddressData['Bitcoin']
					break;
				case "ERC20":
					this.address = this.rechargeAddressData['ETH-ERC20']
					break;
        case "TRC20":
          this.address = this.rechargeAddressData['USDT-TRC20']
        case "USDC-TRC20":
          this.address = this.rechargeAddressData['USDC-TRC20']
          break;
        case "USDC-ERC20":
          this.address = this.rechargeAddressData['USDC-ERC20']
          break;
			}
			// this.address = this.addressData[this.rechargeInfo.name]
			document.getElementById('qrcode').innerHTML = ''
			let qrcode = new QRCode(this.$refs.qrcode, {
				text: this.address,
				width: 200,
				height: 200,
				correctLevel: QRCode.CorrectLevel.H
			})
			this.showRecharge = false
		},
		changeAccount() {
			this.showAccount = true
		},
		chooseAccount(data) {
			this.accountName = data.text
			let form = new FormData()
      form.append('Assets', data.value)
			saveAssets(form).then(res => {
				if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('common.success')
					this.showAccount = false
        } else {  
          this.$store.state.errorMsg = res.msg
        }
			})
		},
    submit(){
      if (!this.form.Amount) {
        this.$store.state.warningMsg = this.i18n.t('recharge.placeholder')
        return
      }
      if (!this.form.PaymentVoucher) {
        this.$store.state.warningMsg = this.i18n.t('recharge.placeholder1')
        return
      }
      let form = new FormData()
      form.append('Amount', this.form.Amount)
      form.append('PaymentVoucher', this.form.PaymentVoucher)
      form.append('Address', this.address)
      form.append('Name', this.rechargeInfo.name)
      rechargeApply(form).then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('userAuth.success2')
          setTimeout(() => {
            this.back()
          }, 500)
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    },
		saveImg() {
			html2canvas(this.$refs.qrcode).then(canvas => {
				let a = document.createElement('a')
				a.href = canvas.toDataURL("image/png")
				a.download = `${this.i18n.t('recharge.img')}.png`
				// document.body.appendChild(a)
				// a.click()
				// document.body.removeChild(a)
				var clickevent = document.createEvent('MouseEvents');
           clickevent.initEvent('click', true, false);
           a.dispatchEvent(clickevent);
			})
		}
	}
}
</script>