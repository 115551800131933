<template>
	<div class="recharge-page withdraw top-bar-page big">
		<top-bar :title="i18n.t('withdraw.title')" class="black" parentId=".withdraw" right-icon="log" @back="back"></top-bar>
	
		<div class="form-label">{{i18n.t('withdraw.label4')}}</div>

		<div class="form-input flex-center-between">
			<input type="number" inputmode="decimal" v-model="form.money" :placeholder="i18n.t('withdraw.labelPlaceholder4')">
			<div class="flex-center adot">
				<span class="btn" @click="setAll">{{i18n.t('transfer.label5')}}</span>
				<span>USDT</span>
			</div>
		</div>

		<div class="flex-center-between desc-line">
			<div>{{i18n.t('transfer.label6')}}</div>
			<div class="count">{{parseFloat(userMoney).toFixed(4)}} USDT</div>
		</div>

		<div class="form-label mt-30">{{i18n.t('withdraw.label2')}}</div>

		<div class="recharge-list">
			<div class="list flex-center" @click="chooseWithdraw">
				<template v-if="withdrawInfo.ID">
					<div class="icon">
						<img :src="iconObj[withdrawInfo.Protocol]" alt="">
					</div>
					<div class="name flex-1 over-text">
						<span>{{withdrawInfo.Address}}</span>
						<span class="desc">{{withdrawInfo.Protocol}}</span>
					</div>
				</template>
				<div class="flex-1 gray" v-else>{{i18n.t('withdraw.labelPlaceholder2')}}</div>
				<div class="check">
					<img :src="rightUrl" alt="">
				</div>
			</div>
		</div>

		

		<div class="form-label">{{i18n.t('withdraw.label3')}}</div>		

		<div class="form-input">
      <input type="tel" pattern="[0-9]*" style="-webkit-text-security:disc" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="form.password" :placeholder="i18n.t('withdraw.labelPlaceholder3')"/>

		</div>

		<div class="bottom-btn flex-center">
			<Button  class="big" @click="submit">{{i18n.t('withdraw.btn2')}}</Button>
		</div>



	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button } from 'vant'
import { withdraw, wallet } from '@/api/assest'
import {formatNumber} from '@/utils/common'
export default {
	components: {
    Popup,
    Button,
		topBar
  },
  data() {
		return {
			form: {
				address: '',
				money: '',
				password: '',
			},
			userMoney: 0,
			closeUrl: require('../../assets/imgs/icon-close.png'),
			backUrl: require('../../assets/imgs/icon-back.png'),
			rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
			logUrl: require('../../assets/imgs/icon-log.png'),
			radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
			showRecharge: false,
      rechargeIndex: 0,
			withdrawInfo: {},
			iconObj:{
        'USDT-TRC20':  require('../../assets/imgs/bit1.png'),
        'USDT-ERC20':  require('../../assets/imgs/bit1.png'),
        'Ethereum':  require('../../assets/imgs/bit2.png'),
        'Bitcoin':  require('../../assets/imgs/bit3.png'),
      },
			withdrawInfoBak: {},
			addressData: []
		}
	},
  mounted() {
		this.init()
		let data = sessionStorage.getItem('withdrawInfo')
    if (data) {
      this.withdrawInfo = JSON.parse(data)
			console.log(111, this.withdrawInfo)
      sessionStorage.setItem('withdrawInfo', '')

      this.form.money = sessionStorage.getItem('withdrawMoney')
      this.form.password = sessionStorage.getItem('withdrawPassword')
    }
  },
	methods: {
		init() {
			let form = new FormData()
      form.append('name', "usdt")
      form.append('current', 1)
      form.append('pageSize', 10)
      wallet(form).then(res => {
				let data = res.data.Items[0]
				if (data) {
					this.userMoney = formatNumber(data.CapitalUse)
				}
      })
		},
		back() {
			this.$router.go(-1)
		},
		toLog() {
			this.$router.push({
				name: 'assestLog',
				query: {
					type: 'withdraw'
				}
			})
		},
		chooseWithdraw() {
			sessionStorage.setItem('withdrawMoney', this.form.money)
      sessionStorage.setItem('withdrawPassword', this.form.password)
			this.$router.push({
				name: 'address',
				query: {
					type: 'withdraw'
				}
			})
    },
		submit() {
			if (!this.form.money) {
				this.$store.state.warningMsg = this.i18n.t('withdraw.labelPlaceholder4')
				return
			}
			if (!this.withdrawInfo.ID) {
				this.$store.state.warningMsg = this.i18n.t('withdraw.labelPlaceholder2')
				return
			}
			if (!this.form.password) {
				this.$store.state.warningMsg =  this.i18n.t('withdraw.labelPlaceholder3')
				return
			}

			let form = new FormData()
			form.append('Id', this.withdrawInfo.ID)
			form.append('Price', this.form.money)
			form.append('Passwd', this.form.password)
			form.append('Type', 'usdt')
			withdraw(form).then(res => {
				if (res.code == 0) {
					this.$store.state.warningMsg = this.i18n.t('common.success')
					this.init()
					this.form.money = ''
					this.form.password = ''
				} else {
				  this.$store.state.errorMsg = res.msg
				}
			})
		},
		setAll() {
			this.form.money = this.userMoney
		},
	}
}
</script>