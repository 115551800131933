import fetch from "../utils/fetch";


// 修改登录密码
export function editPassword(data) {
  return fetch({
    url: '/api/user/change-password',
    method: 'post',
    data: data
  });
}

// CheckPassword
export function CheckPassword(data) {
    return fetch({
        url: '/api/user/check-password',
        method: 'post',
        data: data
    });
}
// 修改安全密码
export function editSafePassword(data) {
  return fetch({
    url: '/api/user/change-safe-password',
    method: 'post',
    data: data
  });
}
// 修改头像
export function editHeader(data) {
  return fetch({
    url: '/api/user/change-avatar',
    method: 'post',
    data: data
  });
}
// 文件上传
export function uploadFile(data) {
  return fetch({
    url: '/api/user/upload',
    method: 'post',
    data: data
  });
}
// 身份认证
export function userIdentity(data) {
  return fetch({
    url: '/api/user/identity',
    method: 'post',
    data: data
  });
}

// 充值申请
export function rechargeApply(data) {
    return fetch({
        url: '/api/user/apply-pay',
        method: 'post',
        data: data
    });
}

// 语言列表
export function langList(data) {
  return fetch({
    url: '/api/user/language',
    method: 'post',
    data: data
  });
}
// 自动划转
export function saveAssets(data) {
  return fetch({
    url: '/api/user/save-assets',
    method: 'post',
    data: data
  });
}
// 用户地址
export function addressList(data) {
  return fetch({
    url: '/api/user/address',
    method: 'post',
    data: data
  });
}
// 认证检查
export function checkAuth(data) {
  return fetch({
    url: '/api/user/check-auth',
    method: 'post',
    data: data
  });
}
// 客服列表
export function customerList(data) {
  return fetch({
    url: '/api/user/customer-list',
    method: 'post',
    data: data
  });
}
// 帮助中心
export function helpList(data) {
  return fetch({
    url: '/api/user/help-center',
    method: 'post',
    data: data
  });
}
// 二次确认
export function confirmSetting(data) {
  return fetch({
    url: '/api/user/secondary-confirmation',
    method: 'post',
    data: data
  });
}