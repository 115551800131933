<template>
  <div class=" download-page">
    <div class="top-bg">
      <div class="back">
        <img :src="backUrl" alt="" @click="back">
      </div>
      <div class="logo flex-column-center">
        <img :src="logoUrl" alt="">
        <span>{{appName}}</span>
      </div>
    </div>

    <div class="download-main">
      <div class="title">{{i18n.t('download.desc1')}}</div>
      <div class="sub-title">{{i18n.t('download.desc2')}}</div>
      <div class="link-modal">
        <div class="link-list flex">
          <div class="link" v-for="(item, i) in linkList" :key="i">
            <img :src="item.icon" alt="">
          </div>
        </div>
        <div class="desc">{{i18n.t('download.desc3')}}</div>
      </div>

      <div class="download-bg">
        <img :src="downloadUrl" alt="" >
      </div>
    </div>
    <div class="flex-center-between download-btn">
      <img :src="btnUrl1" alt="" @click="download('ios')">
      <img :src="btnUrl2" alt="" @click="download('android')">
    </div>

  </div>
</template>
<script>
import topBar from '@/components/topBar'
import {configList} from "@/api/user";
export default {
  components: {
    topBar
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/logo1.png'),
      backUrl: require('../../assets/imgs/icon-back.png'),
      downloadUrl: require('../../assets/imgs/download-bg.png'),
      btnUrl1: require('../../assets/imgs/download-btn1.png'),
      btnUrl2: require('../../assets/imgs/download-btn2.png'),
      iosUrl: '',
      androidUrl: '',
      linkList: [
        {
          icon: require('../../assets/imgs/bit-logo1.png')
        },
        {
          icon: require('../../assets/imgs/bit-logo2.png')
        },
        {
          icon: require('../../assets/imgs/bit-logo3.png')
        },
        {
          icon: require('../../assets/imgs/bit-logo4.png')
        },
        {
          icon: require('../../assets/imgs/bit-logo5.png')
        },
        {
          icon: require('../../assets/imgs/bit-logo6.png')
        },
      ]
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    }
  },
    created() {
      this.init()
    },
    methods: {
      init() {
          configList().then(res => {
              let list = res
              for (let key in list) {
                  if (list.hasOwnProperty(key) && list[key].Name === 'ios_download') {
                      this.iosUrl = list[key].Value;
                  }
                  if (list.hasOwnProperty(key) && list[key].Name === 'android_download') {
                      this.androidUrl = list[key].Value;
                  }
              }
              console.log(this.iosUrl )
              console.log(this.androidUrl)
          })
      },
      download(type) {
          console.log("type")
          console.log(type)
          let url = type == 'ios' ? this.iosUrl : this.androidUrl
          console.log(url)
          let a = document.createElement('a')
          // a.href = url
          a.href = url
          a.target = '_blank'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
      },
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    }
  }
}
</script>